import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEye } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { Link as ScrollLink } from 'react-scroll';
import AOS from 'aos';
import './../assets/css/CompositionListProduct.css';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import { useCart } from "../context/ShoppingCartContext";

import productData from './../data/Product.json';
import HeaderUpdate from '../layouts/Header/HeaderUpdate'
import logo from "../assets/images/logo-page-detail.png";
import Paniericon from "../assets/images/pannier.png";
import PaniericonWhate from "../assets/images/pannier-whate.png";
function DetailProductModal({ product, ...props }) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{fontFamily:'SourceSansPro-Regular, serif'}}>
                    {product.categorie}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={product.image} className='img-fluid w-25' alt="" />
                <h4 className=' m-3' style={{fontFamily:'prata, serif'}}>{product.title}</h4>
                <h5 className=' m-3' >Prix : {product.price}&euro;</h5>

                <p className='text-justify m-3' style={{fontFamily:'montserrat, serif'}}> {product.description1}</p>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{
                        width: "30%",
                        backgroundColor: "#cf9a52",
                        border: "none",
                    }}
                    onClick={props.onHide}>FERMER</Button>
            </Modal.Footer>
        </Modal>
    );
}

const CompositionListProduct = () => {

    const { t } = useTranslation();

    const { addPackProductToCart } = useCart();
    const { id: packId } = useParams();
    //useState for modal
    const [modalShow, setModalShow] = React.useState(false);
    const [Product, setProduct] = useState({});
    const [productQuantities, setProductQuantities] = useState({});
    //useState for pagenation
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;


    // Calculate the index of the first and last products to display on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = productData.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // change the quantity of product by id product
    const handleButtonClick = (action, productId) => {
        const updatedQuantities = { ...productQuantities };
        const currentQuantity = updatedQuantities[productId] || 0;
        if (action === "increment") {
            updatedQuantities[productId] = currentQuantity + 1;
        } else if (action === "decrement" && currentQuantity > 0) {
            updatedQuantities[productId] = currentQuantity - 1;
        }
        setProductQuantities(updatedQuantities);
    }

    // calculate the max quantity all products
    const maxQuantity = Object.values(productQuantities).reduce((acc, curr) => acc + curr, 0)

    const handleAddToCart = () => {
        const packProducts = Object.entries(productQuantities).map(([productId, quantity]) => ({
            productId,
            quantity
        }));
        const filteredPackProducts = packProducts.filter((product) => product.quantity > 0);
        const pack = [{ packID : packId,Products: filteredPackProducts }]
      
        addPackProductToCart(pack);
    }

    useEffect(() => {
        AOS.init({
          // Global settings:
          disable: false, 
          startEvent: 'DOMContentLoaded', 
          initClassName: 'aos-init', 
          animatedClassName: 'aos-animate', 
          useClassNames: false,
          disableMutationObserver: false, 
          debounceDelay: 50, 
          throttleDelay: 99,
          
          // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
          offset: 120, 
          delay: 0,
          duration: 400, 
          easing: 'ease', 
          once: false, 
          mirror: false,
          anchorPlacement: 'top-bottom',
        });
      }, []);
    return (
        <>
        <HeaderUpdate />

        <section className="container-fluid container-fluid-histoire p-0 m-0" style={{background:"none"}}>
                <div className="container container-histoire">
                    <div className="row w-100">
                        <div className="col-md-12 w-100">
                            <div className="col-md-12 ms-2">
                                <h1 className="text-center">{t("compostion-category.productTitle")}</h1>
                            </div>
                            <div className="col-md-12 ms-2">
                                <img
                                    src={logo}
                                    className="img-fluid  m-auto d-block history-img-logo "
                                    alt="miel montet arabesque"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        <section className="section-products">
            <DetailProductModal show={modalShow} product={Product} onHide={() => setModalShow(false)} />
            <div id="list-product" className="container" >
                <div className="row justify-content-center ">
                    {currentProducts.map((product) => (
                        <>
                            <div key={product.id} className=" col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-2 product-item" data-aos="fade-up" data-aos-duration="3000" style={{ border: '1px solid rgb(0, 0, 0, .1) ' }}>
                                <div id="product-1" className="single-product">
                                    <div className="part-1" >
                                        <img src={product.image} className='img-fluid w-75' alt={product.title}/>
                                        <ul className="w-50 d-flex justify-content-around">
                                            <li><a onClick={() => { setModalShow(true); setProduct(product); }}><FaEye /></a></li>
                                        </ul>
                                    </div>
                                    <div className="part-2">
                                        <p className="text-center product-title m-1">{product.title} <span></span></p>
                                        <p className="text-center m-1" style={{fontSize:'15px', fontFamily:"SourceSansPro-Regular"}}>{product.categorie}</p>
                                        <p className="text-center fs-5 m-1" style={{fontFamily:'SourceSansPro-Regular'}}>
                                            {product.price}&euro;
                                        </p>
                                        
                                        <div className="col-md-12 d-flex justify-content-center input-box m-1">
                                            <button type="button" className="btn btn-minus" onClick={() => handleButtonClick("decrement", product.id)}>-</button>
                                            <input type="number" min="1" value={productQuantities[product.id] || 0} readOnly />
                                            {maxQuantity < 2 &&
                                                <button type="button" className="btn btn-plus " onClick={() => handleButtonClick("increment", product.id)} >+ </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </>
                    ))}
                </div>
                <div className="row mt-4 mb-5">
                    <ul className="pagination m-auto pagination-list justify-content-end pe-0">
                        {Array.from({ length: Math.ceil(productData.length / productsPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <ScrollLink key={i} onClick={() => paginate(i + 1)} to='list-product' smooth={true} duration={1000} className=' page-link pagination-link'>
                                    {i + 1}
                                </ScrollLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            
            <div className='row mt-5 w-100 justify-content-end fixed-bottom border_test '  style={{ backgroundColor: '#f9f9f7' }}>
                <div className='col-md-4 mt-3 mb-3 text-right '>
                    <span className=' me-5 fs-5 fw-bold' style={{ color: '#c49c63'}}>{maxQuantity}/2</span>
                    <button className='btn_to_cart' disabled={maxQuantity < 2} onClick={handleAddToCart} style={{ backgroundColor: `${maxQuantity < 2 ? 'rgba(225, 225, 225, 0.5)' : ''}`,color: `${maxQuantity < 2 ? '#9b733c' : ''}`}} >
                        {/*<FiShoppingCart className=' me-2 p-0' style={{ fontSize: '25px'}} />*/}
                        <img src={maxQuantity < 2 ? Paniericon : PaniericonWhate} alt="miel montet pannier" style={{width:"25px", marginRight:10}} />
                        {t("compostion-category.shopBtn")}
                    </button> 
                </div>
                <style jsx>
                    {`
                    .border_test::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 3px;
                        background-color: #c49c63;
                        transition: width 0.9s ease-in-out;
                        width: ${Math.min(maxQuantity * 50, 100)}%;
                    }
                    `}
                </style>
            </div>
        </section>
    </>
    )
}

export default CompositionListProduct
