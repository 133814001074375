import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next';

// import css files
import '../../../assets/css/Home/Sliders/CarouselPage.css'

// import images
import img1 from '../../../assets/images/imgpsh_fullsize_anim.png'
import img2 from '../../../assets/images/imgpsh_fullsize_anim.png'
import img3 from '../../../assets/images/imgpsh_fullsize_anim.png'



const CarouselPage = () => {
  const { t } = useTranslation();
  return <>
    <Carousel>
      <Carousel.Item>
        <img 
          className="d-block w-100 ima-box-slider"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption className="carousel-caption-1">
          <h3>{t('CarouselPage.title1')}</h3> 
        </Carousel.Caption>
        <Carousel.Caption>
          <button type="button" className="btn btn-secondary">{t('CarouselPage.button')}</button>
        </Carousel.Caption> 
      </Carousel.Item>

      <Carousel.Item>
        <img 
          className="d-block w-100 ima-box-slider"
          src={img2}
          alt="Second slide"
        />

        <Carousel.Caption className="carousel-caption-1">
          <h3>{t('CarouselPage.title2')}</h3> 
        </Carousel.Caption>
        <Carousel.Caption>
          <button type="button" className="btn btn-secondary">{t('CarouselPage.button')}</button>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img 
          className="d-block w-100 ima-box-slider"
          src={img3}
          alt="Third slide"
        />

        <Carousel.Caption className="carousel-caption-1">
          <h3>{t('CarouselPage.title3')}</h3> 
        </Carousel.Caption>
        <Carousel.Caption>
          <button type="button" className="btn btn-secondary">{t('CarouselPage.button')}</button>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img 
          className="d-block w-100 ima-box-slider"
          src={img3}
          alt="Third slide"
        />

        <Carousel.Caption className="carousel-caption-1">
          <h3>{t('CarouselPage.title4')}</h3> 
        </Carousel.Caption>
        <Carousel.Caption>
          <button type="button" className="btn btn-secondary">{t('CarouselPage.button')}</button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </>
}

export default CarouselPage;