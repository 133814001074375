import React from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import FormatCurrency from "../../function/FormatCurrency";
import { useCart } from "../../context/ShoppingCartContext";

import CartItem1 from "./CartItems";
import CartItem2 from "./ItemsCoffert";
import CartItem3 from "./ItemsPackProduct";

// import json
import product from "./../../data/Product.json";

import imgPanier from './../../assets/images/panier-vide.png'


const ShoppingCart = ({ isOpen }) => {
  const { t } = useTranslation();
  const { closeCart, cartItems } = useCart();

    let totalPricepack = 0;

// Loop through each item in the cart
cartItems[2].forEach((item) => {
    let totalcartitems = 0;

    // Check if the item contains more than one product
    if (item.Products.length > 1) {
        let totalPriceitem1Product1 = 0;
        let totalPriceitem1Product2 = 0;

        // Find the corresponding products in the product list
        const itemProduct1 = product.find((i) => i.id.toString() === item.Products[0].productId);
        const itemProduct2 = product.find((i) => i.id.toString() === item.Products[1].productId);

        // Use quantity and price to calculate the total price for each product
        const quantityProduct1 = item.Products[0]?.quantity || 0;
        const quantityProduct2 = item.Products[1]?.quantity || 0;
        
        totalPriceitem1Product1 = itemProduct1.price * quantityProduct1;
        totalPriceitem1Product2 = itemProduct2.price * quantityProduct2;

        // Calculate the total for this item
        totalcartitems = totalPriceitem1Product1 + totalPriceitem1Product2;
    } else {
        // Find the corresponding product in the product list
        const itemProduct1 = product.find((i) => i.id.toString() === item.Products[0].productId);

         // Use quantity and price to calculate the total price for the single product
        const quantityProduct1 = item.Products[0]?.quantity || 0;
        const totalPriceitem1Product1 = itemProduct1.price * quantityProduct1;

        // Calculate the total for this item
        totalcartitems = totalPriceitem1Product1;
    }

    // Add the total for this item to the global total price
    totalPricepack += totalcartitems;
});

// Calculate  price
const totalPrice =
cartItems[0].reduce((total, item) => {
  return total + (item.categoryPricePoroduct || 0) * item.quantityPoduct;
}, 0)
+
cartItems[1].reduce((total, item) => {
  return total + (item.priceCoffret || 0) * item.quantityCoffret;
}, 0)  + totalPricepack

  return (
    <Offcanvas id="offcanvas-panier " show={isOpen} onHide={closeCart} placement="end">
      <Offcanvas.Header closeButton >
        <Offcanvas.Title style={{ color: '#cf9a52' }}>{t('cartShop.title')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack gap={3}>
        {cartItems[0].length === 0 && cartItems[1].length === 0 && cartItems[2].length === 0 && (
        <>
        <img src={imgPanier} alt="" className="w-50 m-auto"/>
        <p className=" w-100 text-center text-lead" style={{ color: '#c49c63' }}>
          Votre panier est actuellement vide.
        </p>
        </>
      )}
          {cartItems[0].map((item, index) => (
            <CartItem1 key={index} {...item} />
          ))}
          {cartItems && cartItems[1]?.map((item, index) => (
            <CartItem2 key={index} {...item} />
          ))}
          {cartItems && cartItems[2]?.map((composition, index) => (
            <CartItem3 key={index} index={index} composition={composition} />

          ))}
          <div className="ms-auto fw-bold fs-5" style={{ color: '#00000080' }}>
            Total {FormatCurrency(totalPrice)}
          </div>
          <a href="/page-panier" style={{ color: '#cf9a52' }}>{t('cartShop.link')}</a>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ShoppingCart;
