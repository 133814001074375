import React from 'react'

// import css file

import "./../assets/css/ContactPage.css"

// import images
import logo from './../assets/images/logo-page-detail.png';

// import thes components

import Footer from "../layouts/footer/footer";
import HeaderUpdate from '../layouts/Header/HeaderUpdate';
import ProgressBar from './ProgressBar ';
const ContactPage = () => {
    return <>
        <HeaderUpdate />
        <ProgressBar />
        <div id='container-contact' className="container-fluid ">
            <div className="row justify-content-center" style={{ backgroundColor: "#f9f9f7" }}>
                <div className="col-lg-9" >
                    <div className="col-md-12" >
                        <h1 className=" text-center mb-3" >Nous contacter</h1>
                        <div className="col-md-12 mt-3 ">
                            <img
                                src={logo}
                                className="img-fluid m-auto d-block "
                                alt="" style={{ width: "20%" }} />
                        </div>
                        <h3 className=" text-center mt-3 m-auto  w-50 mb-3" >
                            Une question sur nos miels et coffrets ?
                            Écrivez-nous via notre formulaire de contact
                        </h3>
                    </div>
                    <form className='form-content'>
                        <div className="row g-3 mt-5 mb-5">
                            <div className="col-md-6">
                                <label htmlFor="your-name" className="form-label label-form-contact">Votre nom</label>
                                <input type="text" className="form-control input-form-contact input-form-contact" id="your-name" name="your-name" required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="your-surname" className="form-label label-form-contact">Votre prenom</label>
                                <input type="text" className="form-control input-form-contact" id="your-surname" name="your-surname" required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="your-email" className="form-label label-form-contact">Votre adresse email</label>
                                <input type="email" className="form-control input-form-contact" id="your-email" name="your-email" required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="your-subject" className="form-label label-form-contact">Sujet</label>
                                <input type="text" className="form-control input-form-contact" id="your-subject" name="your-subject" />
                            </div>
                            <div className="col-12">
                                <label htmlFor="your-message" className="form-label label-form-contact">Votre message (en option)</label>
                                <textarea className="form-control input-form-contact" id="your-message" name="your-message" rows="5" required></textarea>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                    <label className="form-check-label" htmlFor="gridCheck">
                                        J'ai pris connaissance de la manière dont mes données sont traitées et j'accepte la politique de protection de vie privée du site
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-contact-Envoyer w-25 fw-bold" >Envoyer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-md-12 mt-5  text-center">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.666808390416!2d2.337533675567839!3d48.88362799893134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f2521d61dbd%3A0xe01b2b3d4be2527c!2sMiel%20Montet!5e0!3m2!1sfr!2sma!4v1709227608162!5m2!1sfr!2sma"
                    width="70%"
                    height="400"
                    style={{ border: "0",marginBottom:"100px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </div>
        </div>
        <Footer />
    </>

}

export default ContactPage