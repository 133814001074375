import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import './../assets/css/Composition.css';


import imgCover from './../assets/images/testimg-1.png';
import img3 from './../assets/images/imgpsh_fullsize_anim-img-3.jpeg';


import Footer from '../layouts/footer/footer';
import HeaderUpdate from '../layouts/Header/HeaderUpdate';
import ProgressBar from './ProgressBar ';

const Composition = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <div className='container-composition container-fluid ps-0 pe-0'>
        <div className='col-md-12 ms-0 me-0 ps-0 pe-0 '>
          <img src={imgCover} alt='imgpsh_fullsize_anim' className='img-fluid img-cover w-100 ms-0 me-0' style={{ height: '80vh' }} />
          <div className='card-img-overlay div-overlay'>
            <h3 className='card-title'>{t('composition.overflow.text')}</h3>
            <Link to='/choisier-votre-coffret' className='btn-commoncer'>
              {t('composition.overflow.btn-link')}
            </Link>
            <ScrollLink to='nos-cpmposition' smooth={true} duration={1000} className='btn-line-work'>
              {t('composition.overflow.link')}
            </ScrollLink>
          </div>
        </div>
        <div id='nos-cpmposition' className='col-md-12 paragraph-box'>
          <div  className='col-md-12 mt-5'>
            <h1 className='text-center pt-4'>{t('composition.title')}</h1>
          </div>
          <div className='col-md-12'>
            <h2 className='text-center'>{t('composition.subtitle')}</h2>
          </div>
          <div className='col-md-12'>
            <p className='text-justify'>{t('composition.translated-text1')}</p>
            <p className='text-justify'>{t('composition.translated-text2')}</p>
            <p className='text-justify'>{t('composition.translated-text3')}</p>
            <p className='text-justify'>{t('composition.translated-text4')}</p>
            <p className='text-justify'>{t('composition.translated-text5')}</p>
          </div>
          <div className='row m-auto justify-content-around mb-5 w-75'>
            <div className="col-md-4 mt-3">
              <img src={img3} alt='imgpsh_fullsize_anim' className='img-fluid img-cover w-100 ms-0 me-0'/>
            </div>
            <div className="col-md-4 mt-3">
              <img src={img3} alt='imgpsh_fullsize_anim' className='img-fluid img-cover w-100 ms-0 me-0'/>
            </div>
            <div className="col-md-4 mt-3">
              <img src={img3} alt='imgpsh_fullsize_anim' className='img-fluid img-cover w-100 ms-0 me-0'/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Composition;
