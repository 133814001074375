import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../assets/css/Home/Sliders/CarouselPage.css';
import './../assets/css/Home/HomePage.css';
import CarouselPage from './../components/Home/Sliders/CarouselPage';
import Card from './../components/Home/Sliders/CardsSlider';
import SliderNormal from "./../components/Home/Sliders/SliderNormal";
import Cards from "../components/Home/cards";
import Footer from "./../layouts/footer/footer";
import img1 from './../assets/images/1.png';
import img2 from './../assets/images/2.jpeg';
import HeaderUpdate from '../layouts/Header/HeaderUpdate';
import ProgressBar from './ProgressBar ';

const Home = () => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);

    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    return (
        <>
            <HeaderUpdate />
            <CarouselPage />
            <Card />
            <ProgressBar />
            <section className='container-fluid container-home'>
                <div className="row justify-content-center h-100" style={{ width: '100%' }}>
                    <div className="col-md-7 d-flex flex-column justify-content-center">
                        <div className='row'>
                            <h1 className='text-center w-100 text-h1'>{t('home.title')}</h1>
                            <p className='text-justify text-p w-75 m-auto pt-3'>
                                <span >{t('home.translated-span1')}{' '}</span>
                                <a href='#none' onClick={handleReadMore} className='read-more-link' style={{ cursor: 'pointer', textDecoration: 'underline', color: '#333', fontWeight: 900 }}>
                                    {showMore ? t('home.translated-link2') : t('home.translated-link1')}
                                </a>
                            </p>
                            {showMore && (
                                <p className='text-justify text-p w-75 m-auto'>
                                    <span>{t('home.translated-span2')}</span>
                                </p>
                            )}
                        </div>
                        <div className="row w-100 m-auto ">
                            <img src={img1} alt="Image 1" className="img-fluid w-75 m-auto" />
                        </div>
                    </div>
                    <div className="col-md-5 d-flex align-items-center justify-content-center">
                        <img src={img2} alt="Image 2" className="img-fluid Image-2 mt-5 mb-5 pt-5 pb-5" />
                    </div>
                </div>
            </section>
            <SliderNormal />
            <Cards />
            <Footer />
        </>
    );
};

export default Home;
