import React from "react";
import { Stack, Button } from "react-bootstrap";

 // import css files
import './../../assets/css/shopping/CartItems.css';
// import useContext
import { useCart } from "../../context/ShoppingCartContext";

import storeItems from "./../../data/Coffrets.json";
import FormatCurrency from "./../../function/FormatCurrency";
const ItemsCoffert = ({ coffretId, quantityCoffret ,priceCoffret}) => {
    
  const {increaseCartQuantityCoffret, decreaseCartQuantityCoffret, removeCoffretFromCart} = useCart();
  const item = storeItems.find((i) => i.id.toString() === coffretId);
  if (item == null) return null;

  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img
      
        src={item.image}
        alt="cart-img"
        style={{ width: "70px", height: "75px", objectFit: "cover" }}
      />
      <div className="me-auto">
        <div>
          {item.title}{" "}
          {quantityCoffret > 1 && (
            
            <span className="text-muted" style={{ fontSize: "0.65rem" }}>
              x{quantityCoffret}
            </span>
            
          )} 
        </div>
        <div className="text-muted" style={{ fontSize: "0.75rem" }}>
          {FormatCurrency(priceCoffret)}&emsp;&emsp; {}
        </div>
        <div className="col-md-12 d-flex align-items-center pt-2 quantity-panier-container">
            <button
            disabled={quantityCoffret< 2}
              type="button"
              className="btn btn-minus button-panier-quantity"
              onClick={() => decreaseCartQuantityCoffret(coffretId)}
            >
              -
            </button>
          <input
            type="text"
            className='text-center m-1 input-panier-quantity'
            min="1"
            value={quantityCoffret} readOnly

          />
          <button
            style={{}}
            type="button"
            className="btn btn-plus button-panier-quantity"
            onClick={() => increaseCartQuantityCoffret(coffretId)}
          >
            +
          </button>
        </div>
      </div>
      <div>{FormatCurrency(priceCoffret * quantityCoffret)}</div>
      <Button
        variant="outline-danger btn-remove-item"
        size="sm"
        onClick={() => removeCoffretFromCart(coffretId)}
      >
        &times;
      </Button>
    </Stack>
  );
};

export default ItemsCoffert;