import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./../assets/css/entreprises.css";
import Footer from "../layouts/footer/footer";
import logo from "./../assets/images/logo-page-detail.png";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import en1 from "./../assets/images/miel-montet-montmartre-800x400.jpg";
import en2 from "./../assets/images/MIELMONTET&HORTICULTUREPAPIER_MC_2022-63.jpg";
import ProgressBar from "./ProgressBar ";

const Entreprises = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <section className="container-fluid container-fluid-entreprise p-0 m-0">
        <div className="container container-entreprise">
          <div className="row w-100">
            <div className="col-md-12 w-100">
              <div className="col-md-12">
                <h1 className="text-center">{t("entreprises.title")}</h1>
              </div>
              <div className="col-md-12">
                <img
                  src={logo}
                  className="img-fluid  m-auto d-block boutique-img-logo "
                  alt=""
                />
              </div>
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-center align-content-center">
                  <h3 className="text-center">{t("entreprises.sotitle")}</h3>
                </div>
              </div>
              <div className="col-md-12">
                <p className="m-auto">
                  {t("entreprises.paragraph1.translated-text1")}
                  <br />
                  <br />
                  {t("entreprises.paragraph1.translated-text2")} <br />
                  <br />
                  {t("entreprises.paragraph1.translated-text3")} <br />
                  <br />
                  {t("entreprises.paragraph1.translated-text4")} <br /> <br />
                  {t("entreprises.paragraph1.translated-text5")}{" "}
                  <Link
                    to="/contact"
                    className="text-black lireplu"
                    onClick={scrollToTop}
                  >
                    {t("entreprises.contact")}
                  </Link>
                </p>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-8">
                  <img src={en1} className="img-fluid image-item" alt="" />
                </div>
              </div>
              <br />
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-center align-content-center">
                  <h3 className="text-center">
                    {t("entreprises.paragraph2.title")}
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <p className="m-auto">
                  {t("entreprises.paragraph2.translated-text1")} <br />
                  <br />
                  {t("entreprises.paragraph2.translated-text2")}
                  <br />
                  <br />
                  {t("entreprises.paragraph2.translated-text3")}
                  <br /> <br />
                  {t("entreprises.paragraph2.translated-text4")}
                  <br /> <br />
                  {t("entreprises.paragraph2.translated-text5")}
                  <Link
                    className="text-black lireplu"
                    to="/contact"
                    onClick={scrollToTop}
                  >
                    {t("entreprises.contact")}
                  </Link>
                </p>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-md-8">
                  <img src={en2} className="img-fluid image-item" alt="" />
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Entreprises;
