import React from "react";
import { useTranslation } from "react-i18next";
import "./../../assets/css/Nos-Miels/filtermiels.css";
import { Link } from "react-router-dom";

const Filtermiels = ({
  categories,
  selectedCategory,
  handleCategoryChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="card cardbody mt-0 ">
        <div className="card-body miels-body mt-0 pt-0 ">
          <nav className="navbar navbar-expand-lg pt-0   nav-miels">
            <Link to="/" className="navbar-brand accueil-menu">
            {t("footer.MielMontet")} {">"}
            </Link>
            <a className="navbar-brand">{t("listemiels.title")}</a>
          </nav>

          <div className="col-auto-12 mb-2 car-title">
            <div className="form-check mb-1 radio-item-miel">
              <input
                className="form-check-input"
                type="radio"
                value=""
                id="radioAllCategories"
                checked={selectedCategory === ""}
                onChange={() => handleCategoryChange("")}
              />
              <label
                className="form-check-label label-product"
                htmlFor="radioAllCategories"
              >
                {t("filtermiles.Touslesproduits")}
              </label>
            </div>
          </div>
          <div className="row catgory-miles m-1">
            {categories.map((category, index) => (
              <div className="col-12" key={index}>
                <div className="form-check mb-1 radio-item-miel">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={category}
                    id={`radioCategory${index}`}
                    checked={selectedCategory === category}
                    onChange={() => handleCategoryChange(category)}
                  />
                  <label
                    className="form-check-label label-miels"
                    htmlFor={`radioCategory${index}`}
                  >
                   {t(`ProductCategory.${category}`)}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default Filtermiels;
