import React from "react";
import "./../../assets/css/Nos-Coffrets/filtercoffrets.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Filtercoffrets = ({
  categoriesCof,
  selectedCoffrets,
  handleCategoryChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container" id="filtercoffrets">
        <div className="row ">
          <div className="a">
            <div className="card-body cof-body">
              <nav className="navbar navbar-expand-lg nav-coff">
                <Link to="/" className="navbar-brand menu-cof" >
                {t("footer.MielMontet")} {">"}
                </Link>
                <a className="navbar-brand">
                  {" "}
                  {t("listecoffrets.title")}
                </a>
              </nav>
              <div className="col-12 cof-title">
                <div className="form-check radio-item-cof mb-1" >
                  <input 
                    className="form-check-input"
                    type="radio"
                    value=""
                    id="radioAllCategoriesCof"
                    checked={selectedCoffrets === ""}
                    onChange={() => handleCategoryChange("")}
                  />
                  <label
                    className="form-check-label label-cofrets pe-0"
                    htmlFor="radioAllCategoriesCof"
                  >
                    {t("filtermiles.Touslesproduits")}
                  </label>
                </div>
              </div>
              <div className="row coffre-miles m-1">
                {categoriesCof.map((catcoffrets, index) => (
                  <div className="col-12" key={index}>
                    <div className="form-check mb-1 radio-item-cof">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={catcoffrets}
                        id={`radioCategoryCof${index}`}
                        checked={selectedCoffrets === catcoffrets}
                        onChange={() => handleCategoryChange(catcoffrets)}
                      />
                      <label
                        className="form-check-label label-cofrets"
                        htmlFor={`radioCategoryCof${index}`}
                      >
                        {t(`CoffretsCategory.${catcoffrets}`)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filtercoffrets;
