import React from "react";
import { Stack, Button } from "react-bootstrap";

// import css files
import './../../assets/css/shopping/CartItems.css';
// import useContext
import { useCart } from "../../context/ShoppingCartContext";

import storeItems from "./../../data/Product.json";
import FormatCurrency from "./../../function/FormatCurrency";
const CartItem = ({ productId, quantityPoduct, categoryId, categoryName, categoryPricePoroduct }) => {
  const { increaseCartQuantity, decreaseCartQuantity, removeProductFromCart } = useCart();
  const item = storeItems.find((i) => i.id.toString() === productId);
  if (item == null) return null;
  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img src={item.image} alt="cart-img" style={{ width: "70px", height: "75px", objectFit: "cover" }}
      />
      <div className="me-auto">
        <div>
          {item.title}{" "}
          {quantityPoduct > 1 && (

            <span className="text-muted" style={{ fontSize: "0.65rem" }}>
              x{quantityPoduct}
            </span>

          )}
        </div>
        <div className="text-muted" style={{ fontSize: "0.75rem" }}>
          {FormatCurrency(categoryPricePoroduct)}&emsp;&emsp; {categoryName}
        </div>

        <div className="col-md-12 d-flex align-items-center pt-2 quantity-panier-container">
         
            <button
              disabled={quantityPoduct < 2}
              type="button"
              className="btn btn-minus button-panier-quantity"
              onClick={() => decreaseCartQuantity(productId, categoryId)}
            >
              -
            </button>
        
          <input
            type="text"
            className='text-center m-1 input-panier-quantity'
            min="1"
            value={quantityPoduct} readOnly

          />
          <button
            style={{}}
            type="button"
            className="btn btn-plus button-panier-quantity"
            onClick={() => increaseCartQuantity(productId, categoryId)}
          >
            +
          </button>
        </div>
      </div>

      <div>{FormatCurrency(categoryPricePoroduct * quantityPoduct)}</div>
      <Button
        variant="outline-danger btn-remove-item"
        size="sm"
        onClick={() => removeProductFromCart(productId, categoryId)}
      >
        &times;
      </Button>
    </Stack>
  );
};

export default CartItem;