import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Filtercoffrets from "./filtercoffrets";
import Footer from "../../layouts/footer/footer";
import imgagelogo from "./../../assets/images/logo-page-detail.png";
import Coffrets from "./../../data/Coffrets.json";
import "./../../assets/css/Nos-Coffrets/listecoffrets.css";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";

const Listecoffrets = () => {
  const { title } = useParams();
  const { t } = useTranslation();
  const [selectedCoffrets, setSelectedCoffrets] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6); // Adjust as needed

  useEffect(() => {
    setSelectedCoffrets("");
    setCurrentPage(1);
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [title]);

  const updateItemsPerPage = () => {
    setItemsPerPage(window.innerWidth <= 500 ? 3 : 6); // Adjust the breakpoint and items per page as needed
  };

  const categoriesCof = Array.from(
    new Set(Coffrets.map((item) => item.catcoffrets))
  );

  const handleCategoryChange = (catcoffrets) => {
    setSelectedCoffrets(catcoffrets);
    setCurrentPage(1);
  };

  const filteredItems =
    selectedCoffrets !== ""
      ? Coffrets.filter((item) => item.catcoffrets === selectedCoffrets)
      : Coffrets;
      
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Conditionally render pagination based on the number of items
  const renderPagination = filteredItems.length > itemsPerPage;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <HeaderUpdate />
      <h1 id="listecoffret" className="title-coffrets text-center mb pt-3">
        {t("listecoffrets.title")}
      </h1>
      <img
        className="logodetail mb-3"
        src={imgagelogo}
        alt=""
        style={{ display: "block", margin: "auto" }}
      />
      <div className="container-fluid" id="listecofret">
        <div className="row">
          <div className="col-md-3">
            <Filtercoffrets
              categoriesCof={categoriesCof}
              handleCategoryChange={handleCategoryChange}
              selectedCoffrets={selectedCoffrets}
            />
          </div>
          <div className="col-md-9">
            <div className="container conatiner-coffrets p-4">
              <div className="row w-100 row-coffrets  d-flex  row-cols-3">
                {currentItems.map((item) => (
                  <div className="col ps-0 pe-0 coffrets-item" key={item.id}>
                    <div className="card card-image border-0">
                      <Link to={`/detail-coffret/${item.id}`}>
                        <div className="card card-image border-0">
                          <img
                            src={item.image}
                            className="card-img-top"
                            alt="Card Image"
                          />
                        </div>
                      </Link>
                      <div className="card-body d-flex flex-column">
                        <Link
                          to={`/detail-coffret/${item.id}`}
                          className=" text-black mt-auto "
                          style={{ textDecoration: "none" }}
                        >
                          <h5 className="title titlecoffrets">
                            {t(`titlecoffrets.${item.title}`)}
                          </h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col px-0 text-end d-flex justify-content-center">
                      <div className=" p-0 mb-3 d-flex-center">
                        <Link
                          to={`/detail-coffret/${item.id}`}
                          className="lire-plus-coffrets text-black mt-auto "
                          onClick={scrollToTop} // Add onClick to scroll to top
                        >
                          {t("listemiels.Lireplus")}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Pagination */}
              {renderPagination && (
                <ul className="pagination pagination-list justify-content-center">
                  {Array.from({
                    length: Math.ceil(filteredItems.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <ScrollLink
                        key={index}
                        onClick={() => paginate(index + 1)}
                        to="listecoffret"
                        smooth={true}
                        duration={1000}
                        className=" page-link pagination-link"
                      >
                        {index + 1}
                      </ScrollLink>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Listecoffrets;
