import React from 'react';
import ReactDOM from 'react-dom/client';
import { ShoppingCartContext } from "./context/ShoppingCartContext"


// import css file app
import './index.css';

// import i18n  
import './i18n'
// import component app
import App from './App';

// import bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js"



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ShoppingCartContext>
    <App />
    </ShoppingCartContext>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

