import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { useCart } from "../context/ShoppingCartContext";
import { useTranslation } from 'react-i18next';

import FormatCurrency from "./../function/FormatCurrency";

//import json 
import DeliveryCategory from "./../data/Delivery.json";
import product from "./../data/Product.json";

// import css file
import './../assets/css/PageCheckout.css'

// import thes components
import Footer from "./../layouts/footer/footer";
import CheckoutCartitmProduct from "../components/shopping/CheckoutCartitmProduct";
import CheckoutCartitmCoffret from "../components/shopping/CheckoutCartitmCoffret";
import CheckoutCartitmPackProduct from "../components/shopping/CheckoutCartitmPackProduct";

// import icons 
import { IoArrowBack } from "react-icons/io5";
import HeaderUpdate from '../layouts/Header/HeaderUpdate';

//import image 
import imgPanier from "./../assets/images/panier-vide.png";

const PagePanier = () => {
    const locale = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();
    const { cartItems } = useCart();
    const [price, setPrice] = useState(DeliveryCategory[0].price);
    let totalPricepack = 0;


    const handleRadioChange = (event) => {
        const Category = DeliveryCategory.find(
            (category) => category.price === parseFloat(event.target.value)
        );
        if (Category) {
            setPrice(parseFloat(event.target.value));
        }

    };

// Loop through each item in the cart
cartItems[2].forEach((item) => {
    let totalcartitems = 0;

    // Check if the item contains more than one product
    if (item.Products.length > 1) {
        let totalPriceitem1Product1 = 0;
        let totalPriceitem1Product2 = 0;

        // Find the corresponding products in the product list
        const itemProduct1 = product.find((i) => i.id.toString() === item.Products[0].productId);
        const itemProduct2 = product.find((i) => i.id.toString() === item.Products[1].productId);

        // Use quantity and price to calculate the total price for each product
        const quantityProduct1 = item.Products[0]?.quantity || 0;
        const quantityProduct2 = item.Products[1]?.quantity || 0;
        
        totalPriceitem1Product1 = itemProduct1.price * quantityProduct1;
        totalPriceitem1Product2 = itemProduct2.price * quantityProduct2;

        // Calculate the total for this item
        totalcartitems = totalPriceitem1Product1 + totalPriceitem1Product2;
    } else {
        // Find the corresponding product in the product list
        const itemProduct1 = product.find((i) => i.id.toString() === item.Products[0].productId);

         // Use quantity and price to calculate the total price for the single product
        const quantityProduct1 = item.Products[0]?.quantity || 0;
        const totalPriceitem1Product1 = itemProduct1.price * quantityProduct1;

        // Calculate the total for this item
        totalcartitems = totalPriceitem1Product1;
    }

    // Add the total for this item to the global total price
    totalPricepack += totalcartitems;
});

    const totalItem = cartItems[0].reduce((total, item) => {
        return total + (item.categoryPricePoroduct || 0) * item.quantityPoduct;
    }, 0)
        +
        cartItems[1].reduce((total, item) => {
            return total + (item.priceCoffret || 0) * item.quantityCoffret;
        }, 0) + totalPricepack

    const calculateTotalPrice = () => {
        ;
        const totaleFinale = (totalItem + price).toFixed(2);
        return totaleFinale;
    };
    return (
        <>
            <HeaderUpdate />
            
                {cartItems[0].length === 0 && cartItems[1].length === 0 && cartItems[2].length === 0 ? (
                    <div className='container text-center cart-empty' >
                        <h1 className="mt-5 ">{locale === "fr" ? "Mon panier" : "My cart"}</h1>
                        <img className='img-fluid mt-5' src={imgPanier} alt={imgPanier}/>
                        <p className=" w-100 text-center mt-5 brawn fs-4">
                        {locale === "fr" ? "Votre panier est actuellement vide." : "Your cart is currently empty."}
                        
                        </p>
                        <Link to="/">
                            {locale === "fr" ? "Retournez sur la page d’accueil" : "Return to the home page"}
                        </Link>
                    </div>
                    
                ) : (
                    <div className="container container-panier mt-5 mb-5">
                    <div className=" card">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8 cart">
                                <div className="title">
                                    <div className="row" style={{ color: '#00000080' }}>
                                        <div className="col"><h4 className='text-left' style={{ color: '#00000080' }}><b>{t('PageCheckout.title')}</b></h4></div>
                                        <div className="col align-self-center text-right text-muted" style={{ color: '#00000080', fontSize: '14px' }}>{t('PageCheckout.lengthProduct')} : {cartItems[0].length + cartItems[1].length + cartItems[2].length} </div>
                                        <a href="/categorie-miels" className="text-decoration-none mt-2 d-flex justify-content-start" style={{ color: '#00000080' }}>
                                            <IoArrowBack style={{ width: '20px', height: '20px' }} />
                                            <span className="text-muted" style={{ color: '#00000080', fontSize: '12px' }}>{t('PageCheckout.link-breckout')}</span>
                                        </a>
                                    </div>

                                </div>

                                {cartItems[0].map((item) => (
                                    <CheckoutCartitmProduct key={item.id} {...item} />
                                ))}

                                {cartItems[1].map((item) => (
                                    <CheckoutCartitmCoffret key={item.id} {...item} />
                                ))}

{
                                    cartItems[2].map((composition , index) => (
                                    <CheckoutCartitmPackProduct key={index} index= {index} composition={composition} cart = {cartItems[2]} />
                                ))}

                                <Accordion className='accordion-panier' defaultActiveKey={['0']} alwaysOpen style={{ marginTop: '50px' }}>
                                    <Accordion.Item eventKey="0" style={{ boxShadow: 'none' }}>
                                        <Accordion.Header className=' Header ' style={{ color: '#00000080' }}>{t('PageCheckout.contact.title')}</Accordion.Header>
                                        <Accordion.Body className='accordion-body'>

                                            <form className="row g-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Email1" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.contact.email.title')}*</label>
                                                    <input type="email" className="form-control text-left" id="Email1" placeholder='s.boukhriss@fcpo.ma' />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="phone" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.contact.phone')}*</label>
                                                    <input type="number" className="form-control text-left" id="phone" placeholder='0600000000' />
                                                </div>
                                            </form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className=' Header '>{t('PageCheckout.Adslivres.title')}</Accordion.Header>
                                        <Accordion.Body>
                                            <form className="row g-3 needs-validation">
                                                <div className="col-md-6">
                                                    <label htmlFor="prenom" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.firstname.title')}</label>
                                                    <input type="text" className="form-control text-left" id="prenom" placeholder={t('PageCheckout.Adslivres.firstname.placeholder')} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nom" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.lastname.title')}</label>
                                                    <input type="text" className="form-control text-left" id="nom" placeholder={t('PageCheckout.Adslivres.lastname.placeholder')} />
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="Address" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.address.title')}</label>
                                                    <input type="text" className="form-control text-left" id="Address" placeholder={t('PageCheckout.Adslivres.address.placeholder')} />
                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="inputState" className="form-label " style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.country')} </label>
                                                    <select id="inputState" className="form-select">
                                                        <option defaultValue={'France'}>France</option>
                                                        <option>Gabon</option>
                                                        <option>Mali</option>
                                                        <option>Maroc</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="inputZip" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.country_code')}</label>
                                                    <input type="text" className="form-control" id="inputZip" />
                                                </div>
                                                <span className='col-md-6'></span>
                                                <div className="col-md-6">
                                                    <label htmlFor="inputZip" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.Adslivres.city')}</label>
                                                    <input type="text" className="form-control" id="inputZip" />
                                                </div>
                                            </form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="col-12 mt-5 textarea-panier">
                                    <label htmlFor="Address" className="form-label" style={{ color: '#00000080' }}>{t('PageCheckout.comment')}</label>
                                    <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>
                                </div>

                            </div>
                            <div className="col-md-4 summary">
                                <div><h5 style={{ color: '#cf9a52' }}><b>{t('PageCheckout.orderSummary')}</b></h5></div>
                                <hr />
                                <div className="row">
                                    <div className="col" style={{ paddingLeft: '0', color: '#cf9a52' }} >{t('PageCheckout.lengthProduct')} : {cartItems[0].length + cartItems[1].length + cartItems[2].length} </div>
                                    <div className="col text-right" style={{ color: '#cf9a52', fontSize: '15px', marginRight: '35px' }}> {FormatCurrency(totalItem)}</div>
                                </div>
                                <form>
                                    <div className='col-12'>
                                        <section className="radio-section" >
                                            <div className="radio-list" >
                                                <h6 className='text-left mb-2' style={{ color: '#cf9a52' }}>{t('PageCheckout.moddliv')}</h6>
                                                {DeliveryCategory.map((Delivery) => (
                                                    <React.Fragment key={Delivery.id}>
                                                        <div className="radio-item ">
                                                            <input name="radio"
                                                                id={`radio${Delivery.id}`}
                                                                type="radio"
                                                                defaultChecked={true}
                                                                value={Delivery.price}
                                                                checked={price === Delivery.price}
                                                                onChange={handleRadioChange}
                                                            />
                                                            <label htmlFor={`radio${Delivery.id}`} className='pe-2 d-flex justify-content-between' style={{ color: '#00000080' }}>
                                                                <span style={{ color: '#00000080' }}>{Delivery.title}</span>
                                                                <span style={{ color: '#cf9a52', fontWeight: 'bold' }}>{FormatCurrency(Delivery.price)}</span>
                                                            </label>
                                                        </div>
                                                    </React.Fragment>
                                                ))}

                                            </div>
                                        </section>
                                    </div>

                                    <div className="row" style={{ borderTop: '1px solid rgba(0,0,0,.1)', padding: '2vh 0' }}>
                                        <div className="col" style={{ color: '#cf9a52' }}>{t('PageCheckout.subTotal')}</div>
                                        <div className="col text-right" style={{ fontSize: '15px', color: '#cf9a52', fontWeight: 'bold', marginRight: '35px' }}>{FormatCurrency(totalItem)}</div>
                                    </div>
                                    <div className="row" style={{ borderTop: '1px solid rgba(0,0,0,.1)', padding: '2vh 0' }}>
                                        <div className="col" style={{ color: '#cf9a52' }}>{t('PageCheckout.shipment')}</div>
                                        <div className="col text-right" style={{ fontSize: '15px', color: '#cf9a52', fontWeight: 'bold', marginRight: '35px' }}>{price.toFixed(2)} &euro;</div>
                                    </div>
                                    <div className="row" style={{ borderTop: '1px solid rgba(0,0,0,.1)', padding: '2vh 0' }}>
                                        <div className="col" style={{ color: '#cf9a52' }}>{t('PageCheckout.total')}</div>
                                        <div className="col text-right" style={{ fontSize: '15px', color: '#cf9a52', fontWeight: 'bold', marginRight: '35px' }}>
                                            {cartItems.length > 0 ? calculateTotalPrice() : 0} &euro;</div>
                                    </div>

                                    <Accordion className='accordion-panier ' defaultActiveKey={['0']} alwaysOpen style={{ marginTop: '50px' }}>
                                        <Accordion.Item eventKey="0" style={{ boxShadow: 'none' }}>
                                            <Accordion.Header className=' Header ' style={{ backgroundColor: 'white !important' }}> {t('PageCheckout.payment.title')} </Accordion.Header>
                                            <Accordion.Body style={{ padding: '10px 0px 10px 0px' }}>
                                                <div className="col-10 col-payment">
                                                    <h3 style={{ color: '#00000080' }}>{t('PageCheckout.payment.subtitle')}</h3>
                                                    <label htmlFor="fname" style={{ color: '#00000080' }}>{t('PageCheckout.payment.label')}</label>
                                                    <div className="icon-container d-flex justify-content-around">
                                                        <i className="fa fa-cc-visa" style={{ color: 'navy', fontSize: '30px' }} ></i>
                                                        <i className="fa fa-cc-amex" style={{ color: 'blue', fontSize: '30px' }}></i>
                                                        <i className="fa fa-cc-mastercard" style={{ color: 'red', fontSize: '30px' }}></i>
                                                        <i className="fa fa-cc-discover" style={{ color: 'orange', fontSize: '30px' }}></i>
                                                    </div>
                                                    <label htmlFor="cname" style={{ color: '#00000080' }}>{t('PageCheckout.payment.namecart.title')}</label>
                                                    <input type="text" id="cname" name="cardname" placeholder={t('PageCheckout.payment.namecart.placeholder')} />
                                                    <label htmlFor="ccnum" style={{ color: '#00000080' }}>{t("PageCheckout.payment.numcart")}</label>
                                                    <input type="text" id="ccnum" name="cardnumber" placeholder="1111-2222-3333-4444" />
                                                    <div className="row" style={{ color: '#00000080' }}>
                                                        <div className="col-md-6">
                                                            <label htmlFor="expyear">{t("PageCheckout.payment.dateEXP")}</label>
                                                            <input type="text" id="expyear" name="expyear" placeholder="MM/YY" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="cvv" style={{ color: '#00000080' }}>CVV</label>
                                                            <input type="text" id="cvv" name="cvv" placeholder="111" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </form>
                                <button className="btn btn-checkout">Procéder au Paiement </button>
                            </div>
                        </div>
                    </div>
                    </div>
                )}

            
            <Footer />
        </>
    )
}

export default PagePanier
