import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import revisit from "./../../assets/images/cookie-svgrepo-com.svg";
import "./../../assets/css/Cookies/cookieConsent.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CookieConsent() {
  const [showMessage, setShowMessage] = useState(true);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showIcon, setShowIcon] = useState(false); // State to control the display of the icon

  useEffect(() => {
    const acceptedCookie = Cookies.get("acceptedCookies");

    if (acceptedCookie === "true") {
      setAcceptedCookies(true);
      setShowMessage(false); // Hide the message if cookies have been accepted
    }
  }, []);

  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    setShowMessage(false);
    Cookies.set("acceptedCookies", "true", { expires: 365 });
  };

  const handleDeclineCookies = () => {
    setShowMessage(false);
    setShowIcon(true); // Show the icon when cookies are declined
    Cookies.set("acceptedCookies", "false", { expires: 365 });
  };

  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };
  const { t } = useTranslation();

  return (
    <>
      {/* Show the cookie consent message */}
      {showMessage && (
        <div className="cookie-consent-container">
          <div className="cookie-consent">
            <div className="row">
              <div className="col-md-12">
                <p className="text-left cky-title" style={{}}>
                  {t("cookies.title")}
                </p>
              </div>

              <div className="col-md-8 des-coki">
                <p className="text-left">
                  {t("cookies.text")}
                  <Link
                    to="/politique-de-confidentialite"
                    style={{ color: "#cc9c57" }}
                  >
                    {t("cookies.lien1")}
                  </Link>
                </p>
              </div>

              <div
                className="col-md-4 d-flex justify-content-between "
                style={{ height: "50px" }}
              >
                <button
                  className="btnrefuser w-50 "
                  style={{ boxShadow: "none !important" }}
                  onClick={handleDeclineCookies}
                >
                  {t("cookies.btn-ref")}
                </button>
                <button
                  className="btnaccpte w-50 ms-5"
                  style={{ boxShadow: "none !important" }}
                  onClick={handleAcceptCookies}
                >
                  {t("cookies.btn-acp")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showIcon && (
        <div className="cookie-icon" onClick={toggleMessage}>
          <img
            src={revisit}
            style={{ height: "30px", width: "30px" }}
            alt="Cookie Consent"
          />
        </div>
      )}
    </>
  );
}

export default CookieConsent;
