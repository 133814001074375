import React from "react";
import "./../../assets/css/Home/cards.css";
import Actus from "../../data/Actus.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cards = () => {
  const { t } = useTranslation();
  const sortedActus = Actus.sort((a, b) => new Date(a.date) - new Date(b.date));
  const firstFourActus = sortedActus.slice(0, 4);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="container card-container-1 mt-5 pt-2 mb-5 ">
      {firstFourActus.map((card) => (
        <div key={card.id} className="card card-description">
          <img
            src={card.image}
            className="card-img-top"
            alt={`Image ${card.id}`}
          />
          <div className="card-info ms-3 me-3">
            <h2 className="style-h2">{card.title}</h2>
            <p className="Medium">{card.date}</p>
            <br />
            <p className="style-p mb-0">{card.Description}</p>
            <div className="lire-b fixed-buttom" >
              <Link to="/nos-actus" className="lien Medium mb-3" onClick={scrollToTop}>
                {t("listemiels.Lireplus")}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;
