import React from 'react'

import { useCart } from "../../context/ShoppingCartContext";
import storeItems from "../../data/Product.json";
import FormatCurrency from "../../function/FormatCurrency";
const CheckoutCartitmProduct = ({ productId, categoryId, quantityPoduct, categoryName, categoryPricePoroduct }) => {
    const { increaseCartQuantity, decreaseCartQuantity, removeProductFromCart } = useCart();
    const item = storeItems.find((i) => i.id.toString() === productId);
    return (
        <>
            {quantityPoduct > 0 && (
                <div key={item.id} className="row border-top border-bottom ">
                    <div className="row main align-items-center">
                        <div className="col-2 box-img"><img className="img-fluid" src={item.image} /></div>
                        <div className="col">
                            <div className="row text-muted" style={{ color: '#00000080' }}>{item.title}</div>
                            <div className="row" style={{ color: '#00000080' }}>{categoryName}</div>
                        </div>
                        <div className="col" >
                            <div className="row d-flex justify-content-center">
                                <div className="col-3 d-flex justify-content-end pe-0 ">
                                {quantityPoduct > 1 && (
                                    <button
                                        type="button"
                                        className="btn btn-minus"
                                        onClick={() => decreaseCartQuantity(productId, categoryId)}
                                    >
                                        -
                                    </button>
                                )}
                                </div>
                                
                                <div className="col-6 d-flex justify-content-center ps-0 pe-0 ">
                                    <input
                                        type="text"
                                        className='text-center input-panier-quantity'
                                        min="1"
                                        value={quantityPoduct} readOnly

                                    />
                                </div>
                                <div className="col-3 d-flex justify-content-start ps-0 ">
                                    <button
                                        type="button"
                                        className="btn btn-plus "
                                        onClick={() => increaseCartQuantity(productId, categoryId)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <span className="price-panier">
                                {FormatCurrency(categoryPricePoroduct * quantityPoduct)}
                            </span>
                            <span className="close">
                                <a href="" className='text-decoration-none' onClick={() => removeProductFromCart(productId, categoryId)}>
                                    &#10005;
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

            )}

        </>
    )
}

export default CheckoutCartitmProduct