import React from "react";
import { Stack, Button } from "react-bootstrap";

 // import css files
import './../../assets/css/shopping/CartItems.css';
// import useContext
import { useCart } from "../../context/ShoppingCartContext";

import compositions from "./../../data/Compostion.json";
import product from "./../../data/Product.json";
import FormatCurrency from "./../../function/FormatCurrency";
const ItemsPackProduct = ({  index, composition }) => {
    const { removepackFromCart } = useCart();
    
    let totalPriceitem1Product1;
    let totalPriceitem1Product2;
    let itemProduct1;
    let itemProduct2;
    let quantityProduct1;
    let quantityProduct2;

  const item = compositions.find((i) => i.id.toString() === composition.packID);
  if (item == null) return null;
  if (composition.Products.length > 1) {
    itemProduct1 = product.find((i) => i.id.toString() === composition.Products[0].productId);
    itemProduct2 = product.find((i) => i.id.toString() === composition.Products[1].productId);
    // Use optional chaining to handle potential errors when 'Products' might be empty
    quantityProduct1 = composition.Products[0]?.quantity || 0;
    quantityProduct2 = composition.Products[1]?.quantity || 0;

    totalPriceitem1Product1 =itemProduct1.price * quantityProduct1 
    totalPriceitem1Product2 = itemProduct2.price * quantityProduct2
  } else {
    itemProduct1 = product.find((i) => i.id.toString() === composition.Products[0].productId);
    quantityProduct1 = composition.Products[0]?.quantity || 0;
    totalPriceitem1Product1 =itemProduct1.price * quantityProduct1 
  }
  
  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img
      
        src={item.image}
        alt="cart-img"
        style={{ width: "70px", height: "75px", objectFit: "cover" }}
      />
      <div className="me-auto">
        <div>
          {item.title}{" "}
        </div>
        <div className="text-muted" style={{ fontSize: "0.75rem" }}>
          {composition.Products.length > 1 ?
              <>{itemProduct1.title} <br />
                {itemProduct2.title}</> :
              <>{itemProduct1.title}</>
            }
        </div>
      </div>
      <div>{
        composition.Products.length > 1 ? FormatCurrency(totalPriceitem1Product1 + totalPriceitem1Product2 ) :
        FormatCurrency(totalPriceitem1Product1)
        }</div>
      <Button
        variant="outline-danger btn-remove-item"
        size="sm"
        onClick={() => removepackFromCart(index)}
      >
        &times;
      </Button>
    </Stack>
  );
};

export default ItemsPackProduct;