import React, { useState } from "react";
import { Link } from "react-router-dom";
import imgagelogo from "./../../assets/images/logo-page-detail.png";
import Footer from "../../layouts/footer/footer";
import "./../../assets/css/Nos-Actus/listeactus.css";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import Actus from "../../data/Actus.json";
import "../../assets/css/Nos-Actus/listeactus.css";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";


const Listeactus = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4); // Adjust as needed
  const { t } = useTranslation();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentActus = Actus.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <HeaderUpdate />
      <h1 className="main-actus text-center pt-3" id="actus" style={{color:'#c49c63'}}>{t("header.ourNews")}</h1>
      <img
        className="logodetail mb-3"
        src={imgagelogo}
        alt=""
        style={{ display: "block", margin: "auto" }}
      />
      <div className="container card-container-1 pt-2 mb-5">
        {currentActus.map((card) => (
          <div key={card.id} className="card card-description">
            <img
              src={card.image}
              className="card-img-top"
              alt={`Image ${card.id}`}
            />
            <div className="card-info ms-3 me-3">
              <h2 className="style-h2">{t(`actusname.${card.title}`)}</h2>
              <p className="Medium">{card.date}</p>
              <br />
              <p className="style-p">
                {card.Description.substring(0, 200)}
                {card.Description.length > 200 && (
                  <Link
                    to={`/detail-actus/${card.id}`}
                    className="expand-button"
                    style={{
                      color: "black",
                      textDecoration: "underline",
                    }}
                  >
                    ...{t("Lire plus")}
                  </Link>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination */}
      {Actus.length > itemsPerPage && (
        <ul className="pagination pagination-list justify-content-center">
          {Array.from({
            length: Math.ceil(Actus.length / itemsPerPage),
          }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <ScrollLink
                key={index}
                onClick={() => paginate(index + 1)}
                to="actus"
                smooth={true}
                duration={1000}
                className=" page-link pagination-link"
              >
                {index + 1}
              </ScrollLink>
            </li>
          ))}
        </ul>
      )}
      <Footer />
    </>
  );
};

export default Listeactus;
