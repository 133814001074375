import React from 'react'
import { useTranslation } from "react-i18next";

// import css file
import './../assets/css/ValuePage.css'

// import images
import logo from './../assets/images/logo-page-detail.png';

// import thes components 
import Footer from "./../layouts/footer/footer";
import HeaderUpdate from '../layouts/Header/HeaderUpdate';
import ProgressBar from './ProgressBar ';

const ValuePage = () => {
    const { t } = useTranslation();
    return (
        <>
            <HeaderUpdate />
            <ProgressBar />
            <div id="container-valus" className="container-fluid ">
                <div className="container  container-valus-content" >
                    <div className="row w-100">
                        <div className="col-md-12 w-100">
                            <div className="col-md-12 ms-2">
                                <h1 className="text-center">{t('valeurs.title')}</h1>
                            </div>
                            <div className="col-md-12 ms-2">
                                <img
                                    src={logo}
                                    className="img-fluid m-auto d-block valus-img-logo"
                                    alt="ligne horizentale miel montet"
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <h3 className="text-center m-auto w-75">
                                    “{t("valeurs.suptitle")}”
                                </h3>
                                <p className="para-value m-auto ">
                                    {t("valeurs.paragraph1.translated-text1")} 
                                    <span>{t("valeurs.paragraph1.translated-span1")} </span> 
                                    {t("valeurs.paragraph1.translated-text2")} 
                                    <span>{t("valeurs.paragraph1.translated-span2")} </span> 
                                    {t("valeurs.paragraph1.translated-text3")}
                                </p>
                                <p className="para-value m-auto ">
                                    {t("valeurs.paragraph2.translated-text1")}
                                    <span>{t("valeurs.paragraph2.translated-span1")}</span> 
                                    {t("valeurs.paragraph2.translated-text2")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer />
        </>
    )
}

export default ValuePage