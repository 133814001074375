import React from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import css files
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './../../../assets/css/Home/Sliders/SliderNormal.css'

// import images
import img1 from '../../../assets/images/imgpsh_fullsize_anim-1.jpeg'
import img2 from '../../../assets/images/imgpsh_fullsize_anim-2.jpeg'


const SliderNormal = () => {
    const { t } = useTranslation();
    const cardCoffret = [
        {
            id: 1,
            title: 'Coffret-Ciel Pourpre',
            description: 'Les arbustes à thé du Fujian déploient leurs feuilles avec élégance et raffinement. Les saveurs s’enlacent, donnant',
            image: img1,
        }
        ,
        {
            id: 2,
            title: 'Coffret-Ciel Pourpre',
            description: 'Les arbustes à thé du Fujian déploient leurs feuilles avec élégance et raffinement. Les saveurs s’enlacent, donnant',
            image: img2,

        }
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className='container-fluid m-auto'>
            <div className="slider-container mt-4" style={{margin: "auto" , width: "80%"}}>
                <h3 className="text-center mb-5">{t("sliderNormal.title")}</h3>
                <Slider {...settings}>
                    {cardCoffret.map((card) => (
                        <div className="col-md-12">
                            <div key={card.id} className="col-md-12   card-content-box">
                                <div className="row ">
                                    <div className="col-sm-6 title-box " style={{height: "20%",  borderRadius: " 7px 0px 0px 7px"}}>
                                        <div className="row pt-3 mt-3">
                                            <h6 style={{ fontSize: "26px", textAlign: "left" }}>{card.title}</h6>
                                        </div>
                                        <div className="row mt-1 " >
                                            <p className='text-box'> {card.description}</p>
                                        </div>
                                        <div className="row d-flex align-items-center ">
                                            <Link
                                                to={`/detail-coffret/${card.id}`}
                                                className='text-center pb-4' style={{ color: "#9e9ca5", fontWeight: "bold" }}>
                                                {t('sliderNormal.link')}
                                            </Link>
                                        </div>
                                    </div>
                                    <img src={card.image} className=' image-slider img-fluid w-50 pe-0 ps-0 ' alt="" style={{borderRadius: " 0 7px 7px 0"}} />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );

}

export default SliderNormal;