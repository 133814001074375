import React from 'react'

import { useCart } from "../../context/ShoppingCartContext";
import compositions from "../../data/Compostion.json";
import product from "./../../data/Product.json";
import FormatCurrency from "../../function/FormatCurrency";
const CheckoutCartitmProduct = ({ categoryName, index, composition, cart }) => {
    const { removepackFromCart } = useCart();
    const item = compositions.find((i) => i.id.toString() === composition.packID);
    if (item == null) return null;

    let totalPriceitem1Product1;
    let totalPriceitem1Product2;
    let itemProduct1;
    let itemProduct2;
    let quantityProduct1;
    let quantityProduct2;

    if (composition.Products.length > 1) {
        itemProduct1 = product.find((i) => i.id.toString() === composition.Products[0].productId);
        itemProduct2 = product.find((i) => i.id.toString() === composition.Products[1].productId);
        // Use optional chaining to handle potential errors when 'Products' might be empty
        quantityProduct1 = composition.Products[0]?.quantity || 0;
        quantityProduct2 = composition.Products[1]?.quantity || 0;

        totalPriceitem1Product1 = itemProduct1.price * quantityProduct1
        totalPriceitem1Product2 = itemProduct2.price * quantityProduct2
    } else {
        itemProduct1 = product.find((i) => i.id.toString() === composition.Products[0].productId);
        quantityProduct1 = composition.Products[0]?.quantity || 0;
        totalPriceitem1Product1 = itemProduct1.price * quantityProduct1
    }
    return (
        <>
            <div key={item.id} className="row border-top border-bottom ">
                <div className="row main align-items-center">
                    <div className="col-2 box-img"><img className="img-fluid" src={item.image} /></div>
                    <div className="col">
                        <div className="row text-muted" style={{ color: '#00000080' }}>{item.title}</div>
                        <div className="row" style={{ color: '#00000080' }}>
                        {composition.Products.length > 1 ?
                            <>{itemProduct1.title} <br />
                            {itemProduct2.title}</> :
                            <>{itemProduct1.title}</>
                        }
                        </div>
                    </div>
                    <div className="col" >
                        <div className="row d-flex justify-content-center">

                        </div>
                    </div>
                    <div className="col">
                        <span className="price-panier">
                            {
                                composition.Products.length > 1 ? FormatCurrency(totalPriceitem1Product1 + totalPriceitem1Product2) :
                                    FormatCurrency(totalPriceitem1Product1)
                            }
                        </span>
                        <span className="close">
                            <a href="" className='text-decoration-none' onClick={() => removepackFromCart(index)}>
                                &#10005;
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckoutCartitmProduct