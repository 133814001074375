import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./views/Home";
import Listemiels from "./components/Nos-Miels/listemiels";
import Listecoffrets from "./components/Nos-Coffrets/listecoffrets";
import DetailMiel from "./views/DetailMiel";
import DetailCofrret from "./views/DetailCofrret";
import PagePanier from "./views/PageCheckout";
import History from "./views/History";
import Contact from "./views/ContactPage";
import Engagement from "./views/EngagementPage";
import CGV from "./views/CGV";
import Mentionslegales from "./views/mentionslegales";
import Listeactus from "./components/Nos-Actus/listeactus";
import Value from "./views/ValuePage";
import Notreboutique from "./views/Notreboutique";
import Entreprises from "./views/entreprises";
import NoteFound from "./views/404";
import CookieConsent from "../src/components/Cookies/CookieConsent";
import Cookies from "./views/PageCookies";
import Composition from "./views/Composition";
import Compositions from "./views/CompositionListProduct";
import Coffretcat from "./components/Voscompositions/CompositionCategory";

function App() {
  return (
    <>
      <BrowserRouter>
        <CookieConsent />
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/nos-actus" element={<Listeactus />} />
          <Route path="/mentions-legales" element={<Mentionslegales />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/categorie-coffrets" index element={<Listecoffrets />}/>
          {/* <Route  path="/categorie-miels/:title" index element={<Listemiels />}  /> */}
          <Route path="/categorie-miels" index element={<Listemiels />} />
          <Route path="/detail-miel/:id" element={<DetailMiel />} />
          <Route path="/detail-coffret/:id" element={<DetailCofrret />} />
          <Route path="/page-panier" element={<PagePanier />} />
          <Route path="/history" element={<History />} />
          <Route path="/botique" element={<Notreboutique />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/engagement-page" element={<Engagement />} />
          <Route path="/value-page" element={<Value />} />
          <Route path="/entreprises" element={<Entreprises />} />
          <Route path="/value-page" element={<Value />} />
          <Route path="/entreprises" element={<Entreprises />} />
          <Route path="/politique-de-confidentialite" element={<Cookies />} />
          <Route path="/choisier-votre-coffret" element={<Coffretcat />} />
          <Route path="/composez-votre-coffret-de-miel"    element={<Composition/>} />
          <Route path="/liste-miles-composition/:id"    element={<Compositions/>} />
          <Route path="*" element={<NoteFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
