import React from "react";
import { useTranslation } from "react-i18next";
// import css file
import "./../assets/css/EngagementPage.css";

// import images

import logo from "./../assets/images/logo-page-detail.png";
import engagementImage from "./../assets/images/engagement-page-img.png";

// import components
import Footer from "../layouts/footer/footer";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import ProgressBar from "./ProgressBar ";

const EngagementPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <div id="engagement-page" className=" container-fluid">
        <div className="content container-engagement-page">
          <div className="row">
            <div className="col-md-12">
              
              <h1 className="text-center">{t('engagements.title')}</h1>
            </div>
            <div className="col-md-12">
              <img
                src={logo}
                className="img-fluid m-auto d-block engagement-img-logo"
                alt=""
              />
            </div>
            <div className="col-md-12 mt-5">
              <h2 className="text-center w-50 m-auto">
                “{t('engagements.suptitle')} »
              </h2>
            </div>

            <div className="col-sm-5 mt-5 mb-5 col-display"></div>
            <div
              className="col-sm-6 mt-5 mb-5 col-paragraph"
              style={{
                // background: "rgba(255,255,255,.5)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <p className=" m-auto   ">
                {t('engagements.paragraph1.translated-text1')}{" "}
                <span>{t('engagements.paragraph1.translated-span1')}</span>{" "}
                {t('engagements.paragraph1.translated-text2')} <span>{t('engagements.paragraph1.translated-span2')}</span>, {t('engagements.paragraph1.translated-text3')}{" "}
                <span>{t('engagements.paragraph1.translated-span3')}</span>, {t('engagements.paragraph1.translated-text4')}{" "}
                <span>{t('engagements.paragraph1.translated-span4')}</span>, {t('engagements.paragraph1.translated-text5')}{" "}
                <span>{t('engagements.paragraph1.translated-span5')}</span> {t('engagements.paragraph1.translated-text6')}{" "}
                <span>{t('engagements.paragraph1.translated-span6')}</span> {t('engagements.paragraph1.translated-text7')}{" "}
                <span>
                  {t('engagements.paragraph1.translated-span7')}
                </span>{" "}
                {t('engagements.paragraph1.translated-text8')}<span> {t('engagements.paragraph1.translated-span8')}</span>{" "}
                {t('engagements.paragraph1.translated-text9')} 
              </p>

              <p className=" m-auto  ">
                {t('engagements.paragraph2.translated-text1')}<span> {t('engagements.paragraph2.translated-span1')}</span> 
                {t('engagements.paragraph2.translated-text2')}<span>{t('engagements.paragraph2.translated-span2')}</span> dans sa
                {t('engagements.paragraph2.translated-text3')}
                <span>{t('engagements.paragraph2.translated-span3')}</span>{" "}
                {t('engagements.paragraph2.translated-text4')}
              </p>

              <p className="m-auto  ">
                {t('engagements.paragraph3.translated-text1')}<span>{t('engagements.paragraph3.translated-span1')}</span> 
                {t('engagements.paragraph3.translated-text2')}
                <span>
                  {" "}
                  {t('engagements.paragraph3.translated-span2')}
                </span>
              </p>
              <div style={{height:'500px'}} className="col-height-display"></div>
            </div>

          </div>
          <div className="row row-display">
            <img src={engagementImage} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EngagementPage;
