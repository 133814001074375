import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/Footer/footer.css";
import logo from "../../assets/images/jadid.png";
import Rectangle from "./rectangle";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <>
      <Rectangle />
      <footer className="footer-box">
        <div className="container-fluid">
          <div className="footer-content m-auto w-100">
            <div
              className="row align-items-start w-100"
              style={{ height: "200px" }}
            >
              <div className="col-md-3" style={{ top: "40px" }}>
                <div className="footer-logo text-center">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="miel montet"
                    style={{ height: "150px" }}
                  />
                </div>
              </div>

              <div className="col-md-9 mt-2 pt-2 ">
                <div className="row justify-content-between mt-4 pt-2">
                  <div className="col-md-3">
                    <ul className="text-left list-inline itmes-list">
                      <li className="">
                        <Link
                          to="/"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                          style={{ color: "#cf9a52" }}
                        >
                          {t("footer.MielMontet")}
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/categorie-miels/t"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                        >
                          {t("footer.NosMiels")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/categorie-coffrets/Ciel%20Pourpre"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                        >
                          {t("footer.NosCoffrets")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/composez-votre-coffret-de-miel"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                        >
                          {t("footer.Voscompositions")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <ul className="text-left list-inline itmes-list">
                      <li>
                        <Link
                          to="/History"
                          className="text-decoration-none"
                          style={{ color: "#cf9a52" }}
                          onClick={scrollToTop}
                        >
                          {t("footer.Informations")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/History" className="text-decoration-none" onClick={scrollToTop}>
                          {t("footer.Notrehistoire")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Engagement-page"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                        >
                          {t("footer.Nosengagements")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value-page" className="text-decoration-none" onClick={scrollToTop}>
                          {t("footer.NosValeurs")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <ul className="text-left list-inline itmes-list">
                      <li>
                        <Link
                          to="/qui-sommes-nous"
                          className="text-decoration-none"
                          style={{ color: "#cf9a52" }}
                          onClick={scrollToTop}
                        >
                          {t("footer.Quisommesnous?")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/Contact" className="text-decoration-none" onClick={scrollToTop}>
                          {t("footer.Contact")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mentions-legales"
                          className="text-decoration-none"
                          onClick={scrollToTop}
                        >
                          {t("footer.Mentionslégales")}
                        </Link>
                      </li>
                      <li>
                        <span>
                          <Link to="/CGV" className="text-decoration-none" onClick={scrollToTop}>
                            {t("footer.CGV")},&nbsp;
                          </Link>
                          <Link
                            to="/politique-de-confidentialite"
                            className="text-decoration-none"
                            onClick={scrollToTop}
                          >
                            {t("footer.Cookies")}
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <ul className="text-left list-inline itmes-list">
                      <li>
                        <Link
                          to="/CGV"
                          className="text-decoration-none"
                          style={{ color: "#cf9a52" }}
                          onClick={scrollToTop}
                        >
                          {t("footer.Noustrouver")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="https://maps.app.goo.gl/iY7e6DNR43AMoBmw9"
                          className="text-decoration-none"
                        >
                          62 rue d’Orsel,75018 Paris
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="text-decoration-none"
                          onClick={() =>
                            (window.location = "tel:-212684086709")
                          }
                          style={{ textDecoration: "none" }}
                        >
                          01 74 64 25 69
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-decoration-none"
                          onClick={() =>
                            (window.location = "mailto:contact@miel-montet.fr")
                          }
                          style={{ textDecoration: "none" }}
                        >
                          contact@miel-montet.fr
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
