import React from "react";
import { Link } from "react-router-dom";

// import json
import productData from "./../../../data/Product.json";
import Coffrets from "./../../../data/Coffrets.json";

// import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../../../assets/css/Home/Sliders/CardsSlider.css";
import { useTranslation } from "react-i18next";



// import thes components
import Slider from "react-slick";
const CardsSlider = ({title , category , catcoffrets}) => {


  const products = category ? productData.filter((product) => product.categorie === category) : catcoffrets 
      ? Coffrets.filter((cofrret) => cofrret.catcoffrets === catcoffrets) : 
      productData;
      
  const settings = {
    dots: true,
    infinite: false, // Disable infinite scrolling if there's only one product
    speed: 500,
    slidesToShow: products.length === 1 ? 1 : 4, // Adjust slidesToShow based on product length
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length === 1 ? 1 : 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <div
      id="slider-container-cards"
      className="slider-container mt-4 mb-5 mb-lg-5 "
      style={{ width: "90%", margin: "auto" }}
    >
      <h3 className="text-center">{title ? title : t('header.Honeys')}</h3>
      <Slider {...settings}>
        {products.map((product) => (
          <div
            key={product.id}
            className="col-sm-12 mb-3 mt-3 d-flex box-cards"
          >
            <Link className="text-decoration-none" to={`/detail-miel/${product.id}`}>
              <div className="card card-image ">
                <img
                  src={product.image}
                  className="card-img-top background-image"
                  alt="Card Image"
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{product.title}</h5>
                  <p className="card-text card-text-slider mb-4">
                    {product.description}
                  </p>
                  <Link
                    to={`/detail-miel/${product.id}`}
                    className=" text-black mt-auto align-self-left "
                  >
                    Lire plus
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardsSlider;
