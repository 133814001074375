import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Miels from "./../../data/Product.json";
import Filtermiels from "./filtermiels";
import Footer from "../../layouts/footer/footer";
import imgagelogo from "./../../assets/images/logo-page-detail.png";
import "./../../assets/css/Nos-Miels/listemiels.css";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";

const Listemiels = () => {
  const { title } = useParams();
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    setSelectedCategory(title || "");
    setCurrentPage(1);
    updateItemsPerPage(); // Update itemsPerPage initially and on window resize
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [title]);

  const updateItemsPerPage = () => {
    setItemsPerPage(window.innerWidth <= 500 ? 8 : 12);
  };

  const categories = Array.from(new Set(Miels.map((item) => item.categorie)));

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredMielItems =
    selectedCategory !== ""
      ? Miels.filter((item) => item.categorie === selectedCategory)
      : Miels;

  const currentItems = filteredMielItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <HeaderUpdate />
      <h1 className="title-miels text-center pt-3 ." id="listemil">
        {t("listemiels.title")}
      </h1>
      <img
        className="logodetail mb-3 "
        src={imgagelogo}
        alt=""
        style={{ display: "block", margin: "auto" }}
      />
      <div className="container-fluid conatiner-liste-miels">
        <div className="row">
          <div className="col-md-3">
            <Filtermiels
              categories={categories}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
            />
          </div>
          <div className="col-md-9 list-mm">
            <div className="container justify-content-start conatiner-product p-1">
              <div className="row  w-100 d-flex row-mobile-miles row-cols-4 ">
                {currentItems.map((item) => (
                  <div
                    className="col flex-wrap justify-content-start mb-5 product-item"
                    key={item.id}
                  >
                    <div className="card card-image border-0">
                      <Link to={`/detail-miel/${item.id}`}>
                        <div className="card card-image border-0">
                          <img
                            src={item.image}
                            className="card-img-top"
                            alt="Card Image"
                          />
                        </div>
                      </Link>
                      <div className="card-body d-flex flex-column">
                        <Link
                          to={`/detail-miel/${item.id}`}
                          className="text-black mt-auto"
                          style={{ textDecoration: "none" }}
                        >
                          <h5 className="title title-product">
                            {t(`titleProduct.${item.title}`)}
                          </h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col px-0 text-end shoppingcard d-flex justify-content-center">
                      <div className="p-0 mb-3  d-flex-center">
                        <Link
                          to={`/detail-miel/${item.id}`}
                          className="text-black mt-auto align-self-left lire-plus-miles"
                          onClick={scrollToTop} 
                        >
                          {t("listemiels.Lireplus")}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Pagination */}
            {filteredMielItems.length > itemsPerPage && (
              <ul className="pagination pagination-list justify-content-center">
                {Array.from({
                  length: Math.ceil(filteredMielItems.length / itemsPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <ScrollLink
                      key={index}
                      onClick={() => paginate(index + 1)}
                      to="listemil"
                      smooth={true}
                      duration={1000}
                      className=" page-link pagination-link"
                    >
                      {index + 1}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Listemiels;
