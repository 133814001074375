import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// import json
import Cofrrets from "../data/Coffrets.json";
import categoryData from "../data/Category.json";

// import context 

import { useCart } from "../context/ShoppingCartContext";

// import css file
import "./../assets/css/PageDetail/Pagedetail.css";

// import thes components
import Footer from "../layouts/footer/footer";
import Card from '../components/Home/Sliders/CardsSlider';

// import images
//

import logodetail from "./../assets/images/logo-page-detail.png";
import Description from "../components/DetailDescription/Description";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import ProgressBar from "./ProgressBar ";


const Ditail = () => {
  const { addProductCoffretToCart } = useCart();
  const { id: coffretId } = useParams();
  const [quantityCoffret, setQuantityCoffret] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(categoryData[0]);


  const handleButtonClick = (action) => {
    if (action === "increment") {
      setQuantityCoffret(quantityCoffret + 1);
    } else if (action === "decrement" && quantityCoffret > 1) {
      setQuantityCoffret(quantityCoffret - 1);
    }
  };


  const product = Cofrrets.find((product) => product.id.toString() === coffretId);
  const { id, title, price ,description, image, catcoffrets  } = product;
  const handleAddToCart = () => {
    const priceCoffret = price
    const categoryCoffretId = selectedCategory.id
    const categoryName = selectedCategory.name;
    const categoryPriceCoffret = selectedCategory.pracecat
    addProductCoffretToCart(coffretId, quantityCoffret ,priceCoffret);
  };
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <section className="container container-detail">
        <div className="container-fluid mt-4" >
          <div className="row w-auto  align-items-start" >
            <div className="col-md-5 m-auto  d-flex align-items-center justify-content-center">
              <div
                id="myCarousel"
                className="row myCarousel-detail-product h-auto justify-content-center align-items-center g-2 carousel slide"
                data-bs-ride="carousel"
                align="center"
              >
                <div className="carousel-inner" >
                  <div className="carousel-item active " style={{ background: 'none' }} >
                    <img src={image} className="img-fluid m-auto" alt="Slide 1"/>
                  </div>
                  <div className="carousel-item">
                    <img src={image} className="img-fluid m-auto" alt="Slide 2" />
                  </div>
                  <div className="carousel-item">
                    <img src={image} className="img-fluid m-auto" alt="Slide 3" />
                  </div>
                </div>
                <ol className="carousel-indicators list-inline " style={{ top: '50%', transform: 'translateY(-20%)'}}>
                  <li className="list-inline-item active pt-0 mt-0" style={{ background: 'none' , opacity: '1' }}>
                    <a
                      id="carousel-selector-0"
                      className="selected pt-0 mt-0"
                      data-bs-slide-to="0"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        src={image}
                        className="img-fluid rounded pt-0 mt-0"
                        alt="Thumbnail 1"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item" style={{ background: 'none'  , opacity: '1'}}>
                    <a
                      id="carousel-selector-1"
                      data-bs-slide-to="1"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        src={image}
                        className="img-fluid rounded"
                        alt="Thumbnail 2"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item" style={{ background: 'none' , opacity: '1' }}>
                    <a
                      id="carousel-selector-2"
                      data-bs-slide-to="2"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        src={image}
                        className="img-fluid rounded"
                        alt="Thumbnail 3"
                      />
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-7 d-grd align-items-center justify-content-center content-detail"style={{  marginTop:'0px !important'}}>
              <div className="col-md-12 d-grid align-items-center " >
                <h1 className="text-center title" >LE MANUKADOUX</h1>
                <p className="text-center mt-2  para-1 "  style={{fontFamily: 'monatge', fontSize:'15pt'}}>
                  “Un miel unique, où se rencontrent saveurs et vertus, pour
                  préserver votre vitalité tout en émerveillant vos sens. Le
                  secret ancestral de la puissance Maorie enfin révélé.”
                </p>
                <img src={logodetail} className="img-fluid w-25 m-auto img-logo" alt="" />
                <h2 className="text-center" style={{fontFamily: 'monatge', }}>
                  Miel de Manuka de Nouvelle-Zélande
                </h2>
              </div>
              <div className="col-md-12 d-grid align-items-center" >
                <p className="text-left para-2" style={{fontFamily: 'Montserrat' , }} >
                  {" "}
                  Ses effluves sont singuliers, sa teinte sombre : ce miel de
                  manuka est un nectar qui se distingue par des notes
                  herbacées discrètement amères. À la dégustation, vousserez
                  enchanté par cette véritable merveille aromatique,empreinte
                  d’élégance.Miel Montet a à cœur de vous partager ce trésor
                  somptueux recueilli en Nouvelle-Zélande.
                </p>
                <h1
                  className="text-left price-h1"
                  id="price"
                  style={{
                    fontFamily: "Montserrat-Regular",
                    fontSize: "30px",
                  }}
                >
                  {/* {calculateTotalPrice()} € */}
                  {price} €
                </h1>
               
              </div>

              <div className="col-md-12 d-grid align-items-center" >
                <div className="col d-flex justify-content-start  ps-0" >
                  {/* <div 
                    className="btn-group ms-0 ps-0 w-75 container-checkbox"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {categoryData.map((category) => (
                      <React.Fragment key={category.id}>
                        <input
                          type="radio"
                          className="btn-check mt-2 ms-1 rounded ps-0 pe-0"
                          name="price"
                          id={`btnradio${category.id}`}
                          autoComplete="off"
                          value={category.pracecat}
                          checked={price === category.pracecat}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="btn btn-checkbox mt-2 ms-0 rounded ps-0 pe-0"
                          htmlFor={`btnradio${category.id}`}
                        >
                          {category.name}
                        </label>
                      </React.Fragment>
                    ))}
                  </div> */}
                </div>
              </div>
              <div className="col-md-12 d-flex input-box mt-5">
                <button
                  type="button"
                  className="btn btn-minus"
                  onClick={() => handleButtonClick("decrement")}
                  style={{ boxShadow: "none", borderRadius: "10px" }}
                >
                  -
                </button>

                <input
                  type="number"
                  min="1"
                  value={quantityCoffret}
                  readOnly
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "1px solid #cf9a52",
                    fontSize: "15px",
                    textAlign: "center",
                    borderRadius: "10%"
                  }}
                />

                <button
                  type="button"
                  className="btn btn-plus "
                  onClick={() => handleButtonClick("increment")}
                  style={{ boxShadow: "none" , borderRadius: "10px"}}
                >
                  +
                </button>
                <button  type="button" className="add-to-cart" onClick={handleAddToCart}>Ajoute au panier</button>
              </div>
              <p
                  className="text text-black-50 ms-4 mt-5"
                  style={{ fontSize: "14px" }}
                >
                </p>
            </div>
          </div>
        </div>
      </section>
      <Description description={description} />
      <Card title="Vous aimerez aussi" catcoffrets={catcoffrets} />
      <Footer />
    </>
  );
};

export default Ditail;
