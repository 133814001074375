import React from "react";
import { useTranslation } from "react-i18next";
// import css file
import "./../assets/css/history.css";
// import thes components
import Footer from "./../layouts/footer/footer";

// import images
import img1 from "./../assets/images/history-1.png";
import logo from "./../assets/images/logo-page-detail.png";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import ProgressBar from "./ProgressBar ";

const History = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <section className="container-fluid container-fluid-histoire p-0 m-0">
        <div className="container container-histoire">
          <div className="row w-100">
            <div className="col-md-12 w-100">
              <div className="col-md-12 ms-2">
                <h1 className="text-center">{t("histoire.title")}</h1>
              </div>
              <div className="col-md-12 ms-2">
                <img
                  src={logo}
                  className="img-fluid  m-auto d-block history-img-logo "
                  alt=""
                />
              </div>
              <div className="row ">
                <div className="col-md-6 d-flex justify-content-center align-content-center mt-3">
                  <h2
                    className="text-center m-auto ms-1 me-1"
                    style={{ lineHeight: "32px" }}
                  >
                    {t("histoire.suptitle")}
                  </h2>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-content-center col-img-histoire mt-3">
                  <img src={img1} className="img-fluid w-50" alt="" />
                </div>
              </div>
              <div className="col-md-12  mt-5">
                <p className="m-auto">
                  {t("histoire.paragraph1.translated-text1")}
                  <span>{t("histoire.paragraph1.translated-span1")}</span>
                  {t("histoire.paragraph1.translated-text2")}{" "}
                  <span>{t("histoire.paragraph1.translated-span2")}</span>
                  {t("histoire.paragraph1.translated-text3")}{" "}
                  <span>{t("histoire.paragraph1.translated-span3")}</span>
                  {t("histoire.paragraph1.translated-text4")}{" "}
                  <span>{t("histoire.paragraph1.translated-span4")}</span>,
                  {t("histoire.paragraph1.translated-text5")}
                  <span>{t("histoire.paragraph1.translated-span5")}</span>
                  {t("histoire.paragraph1.translated-text6")}{" "}
                  <span>{t("histoire.paragraph1.translated-span6")}</span>
                  {t("histoire.paragraph1.translated-text7")}{" "}
                  <span>{t("histoire.paragraph1.translated-span7")}</span>
                  {t("histoire.paragraph1.translated-text8")}{" "}
                  <span>{t("histoire.paragraph1.translated-span8")}</span>
                  {t("histoire.paragraph1.translated-text9")}{" "}
                </p>

                <p className="m-auto">
                  {t("histoire.paragraph2.translated-text1")}{" "}
                  <span>{t("histoire.paragraph2.translated-span1")}</span>
                  {t("histoire.paragraph2.translated-text2")}
                  <span>{t("histoire.paragraph2.translated-span2")}</span>
                  {t("histoire.paragraph2.translated-text3")}{" "}
                  <span>{t("histoire.paragraph2.translated-span3")}</span>
                  {t("histoire.paragraph2.translated-text4")}{" "}
                  <span>{t("histoire.paragraph2.translated-span4")}</span>
                  {t("histoire.paragraph2.translated-text5")}{" "}
                  <span>{t("histoire.paragraph2.translated-span5")}</span>
                  {t("histoire.paragraph2.translated-text6")}{" "}
                  <span>{t("histoire.paragraph2.translated-span6")}</span>
                  {t("histoire.paragraph2.translated-text7")}{" "}
                </p>

                <p className="m-auto">
                  {t("histoire.paragraph3.translated-text1")}
                  <span>{t("histoire.paragraph3.translated-span1")}</span>{" "}
                  {t("histoire.paragraph3.translated-text2")}
                  <span>{t("histoire.paragraph3.translated-span2")}</span>
                  {t("histoire.paragraph3.translated-text3")}{" "}
                  <span>{t("histoire.paragraph3.translated-span3")}</span>
                  {t("histoire.paragraph3.translated-text4")}{" "}
                  <span>{t("histoire.paragraph3.translated-span4")}</span>
                  {t("histoire.paragraph3.translated-text5")}{" "}
                  <span>{t("histoire.paragraph3.translated-span5")}</span>
                  {t("histoire.paragraph3.translated-text6")}
                  <span>{t("histoire.paragraph3.translated-span6")}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 bg-history-img p-0 m-0"></div>
      </section>
      <Footer />
    </>
  );
};

export default History;
