import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "./../../i18n";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import FranceIcon from "../../assets/images/fr.svg";
import UKIcon from "../../assets/images/en.svg";
import Paniericon from "../../assets/images/pannier.png";
import logo from "../../assets/images/jadid.png";
import ProductCategory from "./ProductCategory";
import CoffretsCategory from "./CoffretsCategory";
import { useCart } from "../../context/ShoppingCartContext";
import "./../../assets/css/Header/headerUpdate.css";
import { ReactComponent as Search } from "../../assets/images/searchcolor.svg";
const HeaderUpdate = () => {
  const { t, i18n } = useTranslation();
  const [isSearchVisible, setSearchVisible] = useState(false);
  const { openCart, cartQuantity } = useCart();

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };

  return (
    <>
      <header>
        <ul className="langues">
          <li>
            <img
              src={FranceIcon}
              onClick={() => changeLanguage("fr")}
              alt="miel montet langue Fr"
            />
          </li>
          <li>
            <img
              src={UKIcon}
              onClick={() => changeLanguage("en")}
              alt="miel montet langue En"
            />
          </li>
        </ul>
        <ul className="icons">
          <li>
            <button
              type="button"
              className=" icon-pays border border-0"
              style={{ background: "none"}}
              onClick={openCart}
            >
              <img src={Paniericon} alt="miel montet pannier" />
            </button>

            {cartQuantity > 0 && (
              <div
                className="rounded-circle d-flex justify-content-center align-item-center"
                style={{
                  color: "white",
                  backgroundColor: "#cf9a52",
                  width: "20px",
                  height: "20px",
                  fontSize: "10pt",
                  fontWeight:800,
                  position: "absolute",
                  top: 25,
                  bottom: 30,
                  right: 42,
                  transform: "translate(25%, 25%)",
                  marginRight:10,
                }}
              >
                {cartQuantity}
              </div>
            )}
          </li>
          <li>
            <FaUser color="#cc9c57" style={{ fontSize: "25px" }} />
          </li>
        </ul>
        <div className="clear"></div>

        <div className="marque">
          <div className="leftLines">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="miel montet logo" />
            </Link>
          </div>
          <div className="rightLines">
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="clear"></div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg nav-header   p-0 ">
        <button
          className="navbar-toggler btn-togle btn-tgl"
          style={{
            boxShadow: "none",
            float: "left !important",
          }}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <header className="mt-0 nav-menu" style={{ width: "100%" }}>
            <div
              className="container-fluid size-li text-center w-100"
              style={{ width: "100%" }}
            >
              <div
                className="row justify-content-center text-center"
                style={{ width: "100%" }}
              >
                <div className="col-lg-4 text-center">
                  <ul className="mrg">
                  <Link
                        to="/categorie-miels"
                        className="nav-link"
                      >
                        <li className="nav-item text-black ">
                          {" "}
                          {t("header.ourHoneys")}
                        </li>
                      </Link>
                      <Link
                        to="/categorie-coffrets"
                        className="nav-link"
                      >
                        <li className="nav-item text-black ">
                          {" "}
                          {t("header.ourGiftSets")}
                        </li>
                      </Link>


                    {/* <Link to="" className="nav-link dropdown dropdown-hover">
                      <li
                        className="nav-item  dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("header.ourHoneys")}
                      </li>

                      <ul
                        className="dropdown-menu dropdown-items"
                        aria-labelledby="navbarDropdown"
                        style={{ borderTop: "2px solid #cf9a52" }}
                      >
                        <li className="option-drop p-0 me-0">
                          <ProductCategory />
                        </li>
                      </ul>
                    </Link>
                    <Link href="#" className="nav-link dropdown dropdown-hover">
                      <li
                        className="nav-item  dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("header.ourGiftSets")}
                      </li>

                      <ul
                        className="dropdown-menu dropdown-itemscof  "
                        aria-labelledby="navbarDropdown"
                        style={{ borderTop: "2px solid #cf9a52" }}
                      >
                        <li className="coffrets-drop p-0 me-0">
                          <CoffretsCategory />
                        </li>
                      </ul>
                    </Link> */}

                    
                      <Link
                        to="/composez-votre-coffret-de-miel"
                        className="nav-link"
                      >
                        <li className="nav-item text-black ">
                          {" "}
                          {t("header.yourCompositions")}
                        </li>
                      </Link>
                  </ul>
                </div>
                <div className="col-lg-4 justify-content-center">
                  <ul
                    className="d-inline-flex"
                    style={{ display: "inline-block" }}
                  >
                    <Link to="/history" className="nav-link">
                      <li className="nav-item ">{t("header.ourStory")}</li>
                    </Link>
                    <Link to="/engagement-page" className="nav-link">
                      <li className="nav-item ">
                        {t("header.ourCommitments")}
                      </li>
                    </Link>
                    <Link to="/value-page" className="nav-link">
                      <li className="nav-item ">{t("header.ourValues")}</li>
                    </Link>
                    <Link to="/botique" className="nav-link">
                      <li className="nav-item ">{t("header.ourBoutique")}</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-4 align-items-end">
                  <ul
                    className="d-inline-flex right-menu"
                    style={{ float: "right" }}
                  >
                    <Link to="/nos-actus " className="nav-link pb-0">
                      <li className="nav-item mb-0 ">{t("header.ourNews")}</li>
                    </Link>
                    <Link to="/contact" className="nav-link">
                      <li className="nav-item  ">{t("header.contactUs")}</li>
                    </Link>

                    <Link to="/entreprises" className="nav-link">
                      <li className="nav-item ">{t("header.business")}</li>
                    </Link>
                    <li>
                      <div
                        className="col-sm-auto col-iconseacrh pe-0 ps-0"
                        style={{ width: "2rem" }}
                      >
                        <form className="d-flex ps-0 c form-search justify-content-end">
                          <button
                            id="searchButton"
                            style={{ background: "none" }}
                            className="btn-search my-3 my-sm-0 icon-search"
                            type="button"
                            onClick={toggleSearch}
                          >
                            <Search className="icon icon-se" />
                          </button>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
                <input
                  id="searchInput"
                  className="form-control justify-content-end input-search input-size  mr-sm-3  m-1 me-5"
                  type="search"
                  placeholder={t("header.searchPlaceholder")}
                  aria-label="Search"
                  style={{
                    display: isSearchVisible ? "block" : "none",
                    border: "2px solid #CF9A52",
                  }}
                />
              </div>
            </div>
          </header>
        </div>
      </nav>
    </>
  );
};

export default HeaderUpdate;
