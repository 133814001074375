import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// import json
import productData from "../data/Product.json";
import categoryData from "../data/Category.json";

// import context 
import { useCart } from "../context/ShoppingCartContext";

// import css file
import "./../assets/css/PageDetail/Pagedetail.css";

// import thes components
import Footer from "../layouts/footer/footer";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import Card from '../components/Home/Sliders/CardsSlider';
import Description from "../components/DetailDescription/Description";

// import images
import logodetail from "./../assets/images/logo-page-detail.png";
import ProgressBar from "./ProgressBar ";


const Ditail = () => {
  const { addProductToCart } = useCart();
  const { id: productId } = useParams();
  const [quantityPoduct, setQuantityProduct] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(categoryData[0]);
  const [price, setPrice] = useState(categoryData[0].pracecat);


  const handleButtonClick = (action) => {
    if (action === "increment") {
      setQuantityProduct(quantityPoduct + 1);
    } else if (action === "decrement" && quantityPoduct > 1) {
      setQuantityProduct(quantityPoduct - 1);
    }
  };


  const handleRadioChange = (event) => {
    const selectedCategory = categoryData.find(
      (category) => category.pracecat === parseFloat(event.target.value)
    );
    if (selectedCategory) {
      setPrice(parseFloat(event.target.value));
      setSelectedCategory(selectedCategory);
      setQuantityProduct(1);
    }
  };

  const handleAddToCart = () => {
    const categoryId = selectedCategory.id
    const categoryName = selectedCategory.name;
    const categoryPricePoroduct = selectedCategory.pracecat
    addProductToCart(productId, quantityPoduct, categoryId, categoryName, categoryPricePoroduct);
  };


  const calculateTotalPrice = () => {
    return price.toFixed(2);
  };

  const product = productData.find((product) => product.id.toString() === productId);
  const { id, title, description, image, categorie } = product;
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <section className="container container-detail">
        <div className="container-fluid mt-4" >
          <div className="row w-auto  align-items-start" >
            <div className="col-md-5 m-auto  d-flex align-items-center justify-content-center">
              <div
                id="myCarousel"
                className="row myCarousel-detail-product h-auto justify-content-center align-items-center g-2 carousel slide"
                data-bs-ride="carousel"
                align="center"
              >
                <div className="carousel-inner" style={{border:'1px solid rgb(0, 0, 0, .1) ',borderRadius:'5%' ,zIndex:'3'}}>
                  <div className="carousel-item active ">
                    <img src={image} className="img-fluid m-auto" alt="Slide 1"/>
                  </div>
                  <div className="carousel-item">
                    <img src={image} className="img-fluid m-auto" alt="Slide 2" />
                  </div>
                  <div className="carousel-item">
                    <img src={image} className="img-fluid m-auto" alt="Slide 3" />
                  </div>
                </div>
                <ol className="carousel-indicators list-inline " style={{ top: '50%', transform: 'translateY(-20%)'}}>
                  <li className="list-inline-item active pt-0 mt-0" style={{ opacity: '1'}}>
                    <a
                      id="carousel-selector-0"
                      className="selected pt-0 mt-0"
                      data-bs-slide-to="0"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        style={{border:'1px solid rgb(0, 0, 0, .1) ',borderRadius:'5%'}}
                        src={image}
                        className="img-fluid rounded pt-0 mt-0"
                        alt="Thumbnail 1"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item" style={{ opacity: '1'}}>
                    <a
                      id="carousel-selector-1"
                      data-bs-slide-to="1"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        style={{border:'1px solid rgb(0, 0, 0, .1) ',borderRadius:'5%'}}
                        src={image}
                        className="img-fluid rounded"
                        alt="Thumbnail 2"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item" style={{ opacity: '1'}}>
                    <a
                      id="carousel-selector-2"
                      data-bs-slide-to="2"
                      data-bs-target="#myCarousel"
                    >
                      <img
                        style={{border:'1px solid rgb(0, 0, 0, .1) ',borderRadius:'5%'}}
                        src={image}
                        className="img-fluid rounded"
                        alt="Thumbnail 3"
                      />
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-7 d-grd align-items-center justify-content-center content-detail"style={{  marginTop:'0px !important'}}>
              <div className="col-md-12 d-grid align-items-center ">
                <h1 className="text-center title" >LE MANUKADOUX</h1>
                <p className="text-center mt-2 para-1 "  style={{fontFamily: 'monatge', fontSize:'15pt'}}>
                  “Un miel unique, où se rencontrent saveurs et vertus, pour
                  préserver votre vitalité tout en émerveillant vos sens. Le
                  secret ancestral de la puissance Maorie enfin révélé.”
                </p>
                <img src={logodetail} className="img-fluid w-25 m-auto img-logo" alt="" />
                <h2 className="text-center" style={{fontFamily: 'monatge', }}>
                  Miel de Manuka de Nouvelle-Zélande
                </h2>
              </div>
              <div className="col-md-12 d-grid align-items-center">
                <p className="text-left para-2 w-100" style={{fontFamily: 'Montserrat'}} >
                  {" "}
                  Ses effluves sont singuliers, sa teinte sombre : ce miel de
                  manuka est un nectar qui se distingue par des notes
                  herbacées discrètement amères. À la dégustation, vousserez
                  enchanté par cette véritable merveille aromatique,empreinte
                  d’élégance.Miel Montet a à cœur de vous partager ce trésor
                  somptueux recueilli en Nouvelle-Zélande.
                </p>
                <h1
                  className="text-left price-h1"
                  id="price"
                  style={{
                    fontFamily: "Montserrat-Regular",
                    fontSize: "30px",
                  }}
                >
                  {calculateTotalPrice()} €
                </h1>
                Prix au kilo : 144€
              </div>

              <div className="col-md-12 d-grid align-items-center" >
                <div className="col d-flex justify-content-start  ps-0" >
                  <div 
                    className="btn-group ms-0 ps-0 w-75 container-checkbox"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {categoryData.map((category) => (
                      <React.Fragment key={category.id}>
                        <input
                          type="radio"
                          className="btn-check mt-2 ms-1 rounded ps-0 pe-0"
                          name="price"
                          id={`btnradio${category.id}`}
                          autoComplete="off"
                          value={category.pracecat}
                          checked={price === category.pracecat}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="btn btn-checkbox mt-2 ms-0 rounded ps-0 pe-0"
                          htmlFor={`btnradio${category.id}`}
                        >
                          {category.name}
                        </label>

                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex input-box mt-4">
                <button
                  type="button"
                  className="btn btn-minus"
                  onClick={() => handleButtonClick("decrement")}
                  style={{ boxShadow: "none", borderRadius: "10px" }}
                >
                  -
                </button>

                <input
                  type="number"
                  min="1"
                  value={quantityPoduct}
                  readOnly
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "1px solid #cf9a52",
                    height: "50px",
                    fontSize: "15px",
                    textAlign: "center",
                    borderRadius: "10%"
                  }}
                />

                <button
                  type="button"
                  className="btn btn-plus "
                  onClick={() => handleButtonClick("increment")}
                  style={{ boxShadow: "none" , borderRadius: "10px" }}
                >
                  +
                </button>
                <button  type="button" className="add-to-cart" onClick={handleAddToCart}>Ajoute au panier</button>
              </div>
              <p
                  className="text text-black-50 ms-4 mt-5 "
                  style={{ fontSize: "14px" }}
                >
                </p>
            </div>
          </div>
        </div>
      </section>
      <Description description={description} />
      <Card title="Vous aimerez aussi" category={categorie} />
      <Footer />
    </>
  );
};

export default Ditail;
