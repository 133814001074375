import React from "react";
import "./../../assets/css/PageDetail/liste.css";

const Description = ({ description }) => {
  return (
    <div className="container-fluid-liste w-75" style={{ marginLeft:'10%'}}>
      <div className="row ms-2">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata active fs-5"
              id="description-tab"
              data-bs-toggle="tab"
              data-bs-target="#description"
              type="button"
              role="tab"
              aria-controls="description"
              aria-selected="true"
            >
              Description
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata fs-5"
              id="compositions-tab"
              data-bs-toggle="tab"
              data-bs-target="#compositions"
              type="button"
              role="tab"
              aria-controls="compositions"
              aria-selected="false"
            >
              Compositions
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link nav-link-prata fs-5"
              id="conseils-tab"
              data-bs-toggle="tab"
              data-bs-target="#conseils"
              type="button"
              role="tab"
              aria-controls="conseils"
              aria-selected="false"
            >
              Conseils
            </button>
          </li>
        </ul>
      </div>
      <br />

      <div className="row">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="description"
            role="tabpanel"
            aria-labelledby="description-tab"
          >
            <div className="col Mons">
              {/* <h3 className="text-center">"{description}"</h3> */}
              <p style={{textAlign: "justify"}}>
                Quand le thym est en pleine floraison, il se répand sur les
                collines de Nouvelle-Zélande une brume d’un mauve pastel
                chauffée par les rayons du soleil. L’inattentif qui foule les
                fleurs de cet arbuste vivace sera surpris par l’explosion
                parfumée qui s’élève sous son pas, une odeur persistante aux
                notes boisées et florales. C’est cette même odeur qui charme les
                abeilles invitées à se délecter d’un pollen exquis dont elles
                tirent un miel d’exception.
              </p>
              <p style={{textAlign: "justify"}}>
                Ce nectar est le fruit d’une terre unique enrichie au fil du
                temps. En effet, la Nouvelle-Zélande était une terre vierge
                avant l’arrivée des Maoris. Il y poussait une flore qu’on ne
                trouvait nulle part ailleurs, et ce grâce aux abeilles sauvages,
                fidèles pollinisatrices.
              </p>
              <p style={{textAlign: "justify"}}>
                Il faut attendre le XIXe siècle, avec l’arrivée des abeilles
                domestiquées dans les ruches crées par l’abbé Emile Warré
                apportées par l’apicultrice anglaise Mary Bumby, pour découvrir
                le merveilleux nectar que les abeilles peuvent tirer de la flore
                néo-zélandaise. Le thym, quant à lui, fut introduit quelques
                années plus tard par Jean Désiré Féraud, un arboriculteur
                français. Aux côtés de la marjolaine et de la sauge, il planta
                dans son jardin du thym qui, faisant fi des barrières, se
                répandit comme une traînée de poudre violette dans les collines
                néo-zélandaises. Ce miel est l’incarnation d’un savoir-faire
                partagé, d’une culture des plus riches, celle de la
                Nouvelle-Zélande.
              </p>
              <p style={{textAlign: "justify"}}>
                Le Haka Warré, ce miel de thym qui a su charmer Miel Montet, est
                comme l’écrin d’une flore d’ailleurs : riche et intense. Sa
                texture crémeuse fond en bouche et révèle une saveur fruitée,
                tandis que sa couleur ambrée laisse deviner une intensité qui
                réside dans l’alliance entre arômes caramélisés et puissance
                aromatique.
              </p>
              <p style={{textAlign: "justify"}}>
                Outre ses qualités gustatives, plusieurs recherches concluantes
                sur l’usage médical du miel de thym ont été menées. Bernard
                Descottes, professeur au CHU de Limoges et fondateur de
                l’Association francophone d’apithérapie, était un pionnier dans
                l’utilisation du miel à l’hôpital, et a conclu que le miel de
                thym était « le plus efficace pour la cicatrisation ».
              </p>
              
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="compositions"
            role="tabpanel"
            aria-labelledby="compositions-tab"
          >
            <div className="col Mons">
              <p style={{textAlign: "justify"}}>
                Il faut attendre le XIXe siècle, avec l’arrivée des abeilles
                domestiquées dans les ruches crées par l’abbé Emile Warré
                apportées par l’apicultrice anglaise Mary Bumby, pour découvrir
                le merveilleux nectar que les abeilles peuvent tirer de la flore
                néo-zélandaise.
              </p>
              <p style={{textAlign: "justify"}}>
                Le Haka Warré, ce miel de thym qui a su charmer Miel Montet, est
                comme l’écrin d’une flore d’ailleurs : riche et intense. Sa
                texture crémeuse fond en bouche et révèle une saveur fruitée,
                tandis que sa couleur ambrée laisse deviner une intensité qui
                réside dans l’alliance entre arômes caramélisés et puissance
                aromatique
              </p>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="conseils"
            role="tabpanel"
            aria-labelledby="conseils-tab"
          >
            <div className="col Mons">
              <p style={{textAlign: "justify"}}>
                Il faut attendre le XIXe siècle, avec l’arrivée des abeilles
                domestiquées dans les ruches crées par l’abbé Emile Warré
                apportées par l’apicultrice anglaise Mary Bumby, pour découvrir
                le merveilleux nectar que les abeilles peuvent tirer de la flore
                néo-zélandaise. Le thym, quant à lui, fut introduit quelques
                années plus tard par Jean Désiré Féraud, un arboriculteur
                français. Aux côtés de la marjolaine et de la sauge, il planta
                dans son jardin du thym qui, faisant fi des barrières, se
                répandit comme une traînée de poudre violette dans les collines
                néo-zélandaises. Ce miel est l’incarnation d’un savoir-faire
                partagé, d’une culture des plus riches, celle de la
                Nouvelle-Zélande.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;