import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import coffrets from "../../data/Compostion.json";
import { useTranslation } from "react-i18next";
import HeaderUpdate from "../../layouts/Header/HeaderUpdate";
import "./../../assets/css/Voscompositions/CompositionCategory.css";
import logo from "../../assets/images/logo-page-detail.png";

import "../../assets/css/history.css";
import img1 from "../../assets/images/history-1.png";

const Coffretcat = () => {
  const [selectedCoffrets, setSelectedCoffrets] = useState([]);
  const [showButtonContainer, setShowButtonContainer] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleIconClick = (coffretId) => {

    let updatedSelectedCoffrets;
    if (selectedCoffrets.includes(coffretId)) {
      updatedSelectedCoffrets = [];
    } else {
      updatedSelectedCoffrets = [coffretId];
    }
    setSelectedCoffrets(updatedSelectedCoffrets);
    setShowButtonContainer(updatedSelectedCoffrets.length > 0);
    console.log("PACK ID:", coffretId);
  };

  const isCoffretSelected = (coffretId) => {
    return selectedCoffrets.includes(coffretId);
  };

  const handleContinueClick = () => {
    console.log("Selected Pack ID:", selectedCoffrets);
    const route = `/liste-miles-composition/${selectedCoffrets.join(",")}`;
    navigate(route);
  };

  return (
    <>
      <HeaderUpdate />

      <section className="container-fluid container-fluid-histoire p-0 m-0" style={{background:"none"}}>
        <div className="container container-histoire">
          <div className="row w-100">
            <div className="col-md-12 w-100">
              <div className="col-md-12 ms-2">
                <h1 className="text-center">{t("compostion-category.sotitle")}</h1>
              </div>
              <div className="col-md-12 ms-2">
                <img
                    src={logo}
                    className="img-fluid  m-auto d-block history-img-logo "
                    alt="miel montet arabesque"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container  mt-4" id="block_coffrets-pack">
        <div className="row justify-content-center w-100">
          {coffrets.slice(0, 3).map((coffret) => (
            <div
              key={coffret.id}
              className="col-lg-3 justify-content-center align-content-center col-md-6 mt-3 compos-item"
            >
              <div className="card card-image h-100">
                <img
                  src={coffret.image}
                  className="card-img-top clickable-image"
                  alt={coffret.title}
                  onClick={() => handleIconClick(coffret.id)}
                />
                <div className="card-body">
                  <p
                    className="card-title block__title"
                    style={{ fontFamily: "prata", fontSize: "20px" }}
                  >
                    {t(`compostion-category.${coffret.title}`)}
                  </p>

                  <span
                    className={`composer__checkbox ${
                      isCoffretSelected(coffret.id) ? "active" : ""
                    }`}
                    onClick={() => handleIconClick(coffret.id)}
                  >
                    <span className="icon icon-check" aria-hidden="true"></span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {showButtonContainer && (
          <div className="button-container">
            <button
              className="btnconti"
              style={{ textDecoration: "none" , borderRadius:'7px' }}
              onClick={handleContinueClick}
            >
              {t("compostion-category.CONTINUER")}
            </button>
          </div>
        )}
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Coffretcat;
